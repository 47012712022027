import { TreeModule } from '@ali-hm/angular-tree-component';
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { CoreLegacyModule } from '@app/core-legacy/core-legacy.module';
import { AlertModule } from '@app/modules/alerts/alert.module';
import { CheatSheetModule } from '@app/modules/cheat-sheet/cheat-sheet.module';
import { DirectivesModule } from '@app/modules/directives/directives.module';
import { InputsModule } from '@app/modules/inputs/inputs.module';
import { QueryModule } from '@app/modules/query/query.module';
import { ReadonlyTransformerComponent } from '@app/transformer-form/components/readonly-transformer/readonly-transformer.component';
import { TransformerComponent } from '@app/transformer-form/components/transformer/transformer.component';
import { TransformersFieldDependencyTreeContentComponent } from '@app/transformer-form/components/transformers-field-dependency-tree-content/transformers-field-dependency-tree-content.component';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { NgbAccordionModule, NgbTooltipModule } from '@ng-bootstrap/ng-bootstrap';
import { NgSelectModule } from '@ng-select/ng-select';

@NgModule({
    declarations: [
        ReadonlyTransformerComponent,
        TransformerComponent,
        TransformersFieldDependencyTreeContentComponent
    ],
    imports: [
        AlertModule,
        CheatSheetModule,
        CommonModule,
        CoreLegacyModule,
        DirectivesModule,
        FontAwesomeModule,
        InputsModule,
        NgbAccordionModule,
        NgbTooltipModule,
        NgSelectModule,
        QueryModule,
        ReactiveFormsModule,
        TreeModule
    ],
    exports: [
        ReadonlyTransformerComponent,
        TransformerComponent,
        TransformersFieldDependencyTreeContentComponent
    ]
})
export class TransformerFormModule { }
