import FdxError from '@ajs/models/FdxError';
import AppStateService from '@ajs/services/AppStateService';
import FdxUI from '@ajs/services/fdxUI';
import { Component, OnDestroy, ViewChild } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { CategoryPathsCellRendererComponent, ICategoryPathsCellRendererParams } from '@app/analyze-data/components/category-paths-cell-renderer/category-paths-cell-renderer.component';
import { ChannelAndRegionInputsComponent } from '@app/analyze-data/components/channel-and-region-inputs/channel-and-region-inputs.component';
import { CountryDropdownOption } from '@app/analyze-data/components/column-stats/column-stats.component';
import { COLUMN_STATS_EMPTY } from '@app/analyze-data/constants/analyze-data.constants';
import { CategoryPathsUtilitiesService } from '@app/analyze-data/services/category-paths-utilities.service';
import { ColumnStatsUtilitiesService } from '@app/analyze-data/services/column-stats-utilities.service';
import { DvApiRequestFormatterService } from '@app/analyze-data/services/dv-api-request-formatter.service';
import { ChannelOptionsType } from '@app/analyze-data/types/channel-options.type';
import { ColumnStatsColumnEnum, ColumnStatsParentRowData } from '@app/analyze-data/types/column-stats.types';
import { CategoryTaxonomyDataService } from '@app/category-taxonomy/services/category-taxonomy-data.service';
import { UniqueAttributesRequest } from '@app/category-taxonomy/services/requests/unique-attributes.request';
import { UniqueAttributeField } from '@app/category-taxonomy/services/responses/unique-attributes.response';
import { ChannelClient } from '@app/core/models/enums/channel-client.enum';
import { ConditionType } from '@app/core/models/enums/condition-type.enum';
import { FdxBlockUIService } from '@app/core/services/fdx-block-ui.service';
import { DataPageExport } from '@app/data/enums/data-page-export.enum';
import { DbFieldModel } from '@app/databases/models/db-field.model';
import { DatabasesDataService } from '@app/databases/services/databases-data.service';
import { AnalyzeFieldsRequest } from '@app/databases/services/requests/analyze-fields.request';
import { AnalyzeFieldsByNameResponse } from '@app/databases/services/responses/analyze-fields-by-name.response';
import { DestinationMetadata } from '@app/exports/models/export.model';
import { ExportField } from '@app/exports/services/responses/get-exports.response';
import { requiredCheckboxOrRadioValidator } from '@app/modules/inputs/validators/required-checkbox-or-radio.validator';
import { Offcanvas } from '@app/modules/offcanvas/offcanvas.module';
import { SearchBarComponent } from '@app/modules/search-bar/components/search-bar/search-bar.component';
import { faCircle as faCircleEmpty } from '@fortawesome/pro-regular-svg-icons';
import { IconDefinition, faArrowRight, faCircle, faCircleHalfStroke, faExclamationCircle, faPlus, faTableList } from '@fortawesome/pro-solid-svg-icons';
import { NgbActiveOffcanvas } from '@ng-bootstrap/ng-bootstrap';
import { AllCommunityModule, CellRendererSelectorResult, ClientSideRowModelModule, ColDef, GridApi, GridOptions, GridReadyEvent, ICellRendererParams, IRowNode, Module, RowDataUpdatedEvent, ValueFormatterParams } from 'ag-grid-community';
import { BehaviorSubject, EMPTY, Subject, catchError, map, takeUntil, tap, throwError } from 'rxjs';

export interface ExportFieldsOffcanvasResolver {
    dbFields: DbFieldModel[],
    exportFields: ExportField[],
    missingFields?: string[],
    exportSelector: string,
    destinationMetadata: DestinationMetadata
}

@Component({
    selector: 'fdx-export-fields-offcanvas',
    templateUrl: './export-fields-offcanvas.component.html',
    styleUrls: ['./export-fields-offcanvas.component.scss']
})
export class ExportFieldsOffcanvasComponent implements Offcanvas, OnDestroy {
    public title: string = 'Add export fields';
    public readonly icon: IconDefinition = faPlus;
    readonly requiredIcon: IconDefinition = faCircle;
    readonly recommendedIcon: IconDefinition = faCircleHalfStroke;
    readonly optionalIcon: IconDefinition = faCircleEmpty;
    readonly listIcon: IconDefinition = faTableList;
    readonly arrowRightIcon: IconDefinition = faArrowRight;
    readonly errorIcon: IconDefinition = faExclamationCircle;

    readonly conditionType: typeof ConditionType = ConditionType;

    @ViewChild(ChannelAndRegionInputsComponent) channelAndRegionInputs: ChannelAndRegionInputsComponent;
    @ViewChild(SearchBarComponent) searchBar: SearchBarComponent;
    private readonly unsubscribe$: Subject<void> = new Subject<void>();

    databaseId: string = this.appStateService.getDatabaseId();

    columns: string[] = [];
    exportFields: ExportField[] = [];
    columnsToAutosize: string[] = [];
    missingFields: string[] = [];

    private static defaultExportSelector: string = 'true';
    exportSelector: string = ExportFieldsOffcanvasComponent.defaultExportSelector;

    configuredChannel: ChannelOptionsType = null;

    loadingChannels: boolean = true;
    loadingColumnStatsResults: boolean = false;
    initColumnStatsGrid: boolean = false;
    searchValue: string = null;

    // eslint-disable-next-line @typescript-eslint/typedef
    formGroup = new FormGroup({
        channel: new FormControl<ChannelOptionsType>(null, Validators.required),
        country: new FormControl<CountryDropdownOption>(null, Validators.required),
        channelCategoryField: new FormControl<string>(null, Validators.required)
    });

    // eslint-disable-next-line @typescript-eslint/typedef
    conditions = new FormGroup({
        [ConditionType.REQUIRED]: new FormControl<boolean>(true, { nonNullable: true, validators: Validators.required }),
        [ConditionType.RECOMMENDED]: new FormControl<boolean>(false, { nonNullable: true, validators: Validators.required }),
        [ConditionType.OPTIONAL]: new FormControl<boolean>(false, { nonNullable: true, validators: Validators.required })
    }, requiredCheckboxOrRadioValidator());

    get channel(): ChannelOptionsType {
        return this.formGroup?.value.channel;
    }

    get country(): CountryDropdownOption {
        return this.formGroup?.value.country;
    }

    lastCountry?: CountryDropdownOption;

    rowData$: BehaviorSubject<ColumnStatsParentRowData[]> = new BehaviorSubject<ColumnStatsParentRowData[]>([]);

    // #region AG GRID
    private gridApi: GridApi<ColumnStatsParentRowData>;

    public readonly modules: Module[] = [AllCommunityModule, ClientSideRowModelModule];

    public readonly defaultColDef: ColDef = {
        sortable: true,
        resizable: false,
        suppressHeaderMenuButton: true,
        unSortIcon: true
    };

    public gridOptions: GridOptions = {
        defaultColDef: this.defaultColDef,
        suppressMenuHide: false,
        suppressMovableColumns: true,
        enableBrowserTooltips: true,
        tooltipShowDelay: 300,
        suppressRowTransform: true,
        suppressCellFocus: true,
        isExternalFilterPresent: () => this.searchValue !== null,
        doesExternalFilterPass: (node: IRowNode<ColumnStatsParentRowData>) => this.doesFilterPass(node),
        rowSelection: {
            mode: 'multiRow',
            checkboxes: true,
            headerCheckbox: true,
            selectAll: 'filtered',
            enableClickSelection: true
        }
    };
    // #endregion

    get numSelectedRows(): number {
        if (!this.gridApi) {
            return 0;
        }

        return this.gridApi.getSelectedRows().length;
    }

    get numTotalRows(): number | null {
        return this.rowData$?.value?.length;
    }

    get shouldDisplaySelectCategoriesError(): boolean {
        return !this.loadingColumnStatsResults && this.numTotalRows > 0 && this.numSelectedRows === 0;
    }

    get shouldDisableAddExportButton(): boolean {
        return !this.conditions.valid || this.numSelectedRows === 0;
    }

    get categoriesSelectedText(): string | null {
        const selected = this.numSelectedRows;
        const total = this.numTotalRows;
        if (!total) {
            return null;
        }

        return `${selected}/${total} categories selected`;
    }

    constructor(
        private readonly appStateService: AppStateService,
        private readonly categoryPathsUtilitiesService: CategoryPathsUtilitiesService,
        private readonly categoryTaxonomyDataService: CategoryTaxonomyDataService,
        private readonly columnStatsUtilitiesService: ColumnStatsUtilitiesService,
        private readonly databasesDataService: DatabasesDataService,
        private readonly dvApiRequestFormatterService: DvApiRequestFormatterService,
        private readonly fdxBlockUIService: FdxBlockUIService,
        private readonly fdxUI: FdxUI,
        private readonly offcanvas: NgbActiveOffcanvas
    ) { }

    public onOffcanvasInit(resolve: ExportFieldsOffcanvasResolver): void {
        this.columns = resolve.dbFields.map<string>((dbField: DbFieldModel) => dbField.field_name);
        this.exportFields = resolve.exportFields;
        this.missingFields = resolve.missingFields ?? [];
        this.exportSelector = resolve.exportSelector;

        const metadataArray = this.getMetadataArray(resolve.destinationMetadata);

        if (metadataArray?.length === 1) {
            this.configuredChannel = metadataArray[0];
            this.parseColumns();
        }
    }

    private getMetadataArray(destinationMetadata: DestinationMetadata): ChannelOptionsType[] | null {
        return destinationMetadata ? Object.values(destinationMetadata) : null;
    }

    private parseColumns(): void {
        if (!this.configuredChannel) {
            return;
        }

        const channelCategoryField = this.configuredChannel.db_category_field;
        if (channelCategoryField && this.columns.includes(channelCategoryField)) {
            this.formGroup.controls.channelCategoryField.patchValue(channelCategoryField);
        }
    }

    toggleCondition(condition: ConditionType): void {
        this.conditions.patchValue({
            [condition]: !this.conditions.value[condition]
        });
    }

    onLoadedChannels(): void {
        this.loadingChannels = false;
        this.parseChannelAndRegions();
    }

    private parseChannelAndRegions(): void {
        if (!this.configuredChannel) {
            return;
        }

        const channelName = this.configuredChannel.display;

        this.channelAndRegionInputs.findMatchingChannelOld(channelName).pipe(
            tap((channel: ChannelOptionsType) => {
                /** If no matching channel, immediately return */
                if (!channel) {
                    return;
                }

                let countryName: string;
                if (channel.destinations?.length === 1) {
                    const destination = channel.destinations[0];
                    countryName = `${destination.country}-${destination.region}`;
                }

                /**
                 * When patching the channel value, only emit the event if we don't have a country name, since we
                 * will update the country name manually by calling `updateCountries` right after this
                 */
                this.formGroup.controls.channel.patchValue(channel, { emitEvent: Boolean(!countryName) });

                if (countryName) {
                    this.channelAndRegionInputs.updateCountries(channel, countryName);
                }

                if (this.formGroup.valid) {
                    this.onLoadCategoriesClick(); // Automatically load column stats if the form is now valid
                }
            }),
            takeUntil(this.unsubscribe$)
        ).subscribe();
    }

    onLoadCategoriesClick(): void {
        this.loadingColumnStatsResults = true;
        this.loadColumnStatsResults();

        if (this.initColumnStatsGrid) {
            this.searchBar.resetSearchBar();
        } else {
            this.initColumnStatsGrid = true;
        }
    }

    private loadColumnStatsResults(withoutExportIf: boolean = false): void {
        if (this.gridApi && this.lastCountry !== this.country) {
            this.gridApi.setGridOption('columnDefs', this.getColumnDefs());
        }

        this.gridOptions.suppressNoRowsOverlay = true;

        const params: AnalyzeFieldsRequest = {
            marketplace: this.country.marketplace
        };

        const fqlFilter: string = withoutExportIf ? null : this.exportSelector;

        this.databasesDataService.getAnalyzedFieldByName(this.databaseId, DataPageExport.GeneralExport, this.formGroup.controls.channelCategoryField.value, null, fqlFilter, params)
            .pipe(
                catchError(() => {
                    /**
                     * If the fqlFilter isn't equal to 'true' and this isn't our retry without the export if selector,
                     * initiate a retry without the export if selector so we can determine the true cause of the error.
                     */
                    if (fqlFilter !== ExportFieldsOffcanvasComponent.defaultExportSelector && !withoutExportIf) {
                        this.loadColumnStatsResults(true);
                        return EMPTY;
                    }

                    this.loadingColumnStatsResults = false;
                    this.initColumnStatsGrid = false;

                    /**
                     * If we got here, there was an error analyzing the data that has nothing to do with the export if selector
                     */
                    this.fdxUI.showToastError('Something went wrong analyzing the column stats.');
                    return throwError(() => new FdxError('UNKNOWN_ERROR', 'Error retrieving data'));
                }),
                map((response: AnalyzeFieldsByNameResponse) => this.columnStatsUtilitiesService.convertToRowDataType(response)),
                tap(({ parentRowData }) => {
                    this.loadingColumnStatsResults = false;

                    /**
                     * If we have a successful call after retrying without the export if selector, there was something wrong with the export if selector.
                     * Prompt the user to try again.
                     */
                    if (withoutExportIf) {
                        this.initColumnStatsGrid = false;
                        this.fdxUI.showToastError('Something went wrong analyzing the column stats. Please check your export if selector and try again.', false, 10000);
                        return;
                    }

                    const emptyIndex: number = parentRowData.findIndex((row: ColumnStatsParentRowData) => row[ColumnStatsColumnEnum.UniqueValue].value === '');
                    if (emptyIndex >= 0) {
                        parentRowData.splice(emptyIndex, 1);
                    }

                    if (!parentRowData?.length) {
                        this.gridOptions.suppressNoRowsOverlay = false;
                        this.gridApi.showNoRowsOverlay();
                    }

                    this.rowData$.next(parentRowData);
                }),
                takeUntil(this.unsubscribe$)
            ).subscribe();
    }

    private getColumnDefs(): ColDef[] {
        this.lastCountry = this.country;

        this.columnsToAutosize = [];
        const colDefs: ColDef[] = [];

        const categoryColumn: ColDef = {
            field: ColumnStatsColumnEnum.UniqueValue,
            headerName: 'Category',
            headerClass: () => {
                if (!this.numTotalRows) {
                    return 'disable-checkbox'
                }
                return null;
            },
            valueFormatter: (params: ValueFormatterParams<ColumnStatsParentRowData>): string => {
                return this.categoryPathsUtilitiesService.getDisplayedValue(params.data[ColumnStatsColumnEnum.UniqueValue]);
            }
        };

        switch (this.country.client) {
            case ChannelClient.Amazon:
                colDefs.push(
                    {
                        ...categoryColumn,
                        flex: 1,
                        cellRendererSelector: (params: ICellRendererParams<ColumnStatsParentRowData>): CellRendererSelectorResult => {
                            return {
                                component: CategoryPathsCellRendererComponent,
                                params: {
                                    channel: this.channel,
                                    country: this.country,
                                    config: params.data[ColumnStatsColumnEnum.UniqueValue]
                                } satisfies ICategoryPathsCellRendererParams
                            };
                        },
                        comparator: this.categoryPathsUtilitiesService.getComparatorFn()
                    }
                );
                break;
            case ChannelClient.Google:
                colDefs.push(
                    {
                        ...categoryColumn,
                        wrapText: true,
                        autoHeight: true,
                        flex: 1
                    }
                );
                break;
            default:
                colDefs.push(
                    {
                        ...categoryColumn
                    },
                    {
                        field: 'path',
                        flex: 1,
                        cellClass: 'text-secondary fs-smaller',
                        wrapText: true,
                        autoHeight: true,
                        valueFormatter: (params: ValueFormatterParams<ColumnStatsParentRowData>): string => {
                            const categoryPaths = params.data[ColumnStatsColumnEnum.UniqueValue].categoryPaths;
                            if (categoryPaths?.length > 0) {
                                return this.categoryPathsUtilitiesService.getDisplayedPath(categoryPaths[0]);
                            }

                            return null;
                        }
                    }
                );

                this.columnsToAutosize.push(ColumnStatsColumnEnum.UniqueValue);
                break;
        }

        colDefs.push({
            field: ColumnStatsColumnEnum.Count,
            headerName: '# items',
            type: 'numericColumn',
            sort: 'desc',
            valueFormatter: (valueGetterParams: ValueFormatterParams<ColumnStatsParentRowData, number>): string => valueGetterParams.value.toLocaleString()
        });

        this.columnsToAutosize.push(ColumnStatsColumnEnum.Count);

        return colDefs;
    }

    onSearchChange(value: string): void {
        this.searchValue = value ? value.toLowerCase() : null;

        this.gridApi.onFilterChanged();

        // determine if we should show a no rows overlay message
        if (this.gridApi.getRenderedNodes().length === 0) {
            this.gridOptions.suppressNoRowsOverlay = false;
            this.gridApi.showNoRowsOverlay();
        } else {
            this.gridApi?.setGridOption('loading', false);
            this.gridApi.hideOverlay();
            this.gridOptions.suppressNoRowsOverlay = true;
        }
    }

    private doesFilterPass(node: IRowNode<ColumnStatsParentRowData>): boolean {
        if (!node.data) {
            return true;
        }

        const rowValue = node.data[ColumnStatsColumnEnum.UniqueValue].value;
        const categoryMatch = this.doesSearchMatchCategory(rowValue, this.searchValue);

        if (categoryMatch) {
            return true;
        }

        /** Amazon and Google do not display paths in the table data, so only search on the category in those cases.
         * Otherwise, also search on the displayed path.
         */
        const categoryPaths = node.data[ColumnStatsColumnEnum.UniqueValue].categoryPaths;
        switch (this.country.client) {
            case ChannelClient.Amazon:
            case ChannelClient.Google:
                return false;
            default:
                if (categoryPaths?.length > 0) {
                    return this.doesSearchMatchPath(categoryPaths[0].path, this.searchValue);
                }
                return false;
        }
    }

    private doesSearchMatchCategory(rowValue: string, searchValue: string): boolean {
        return rowValue === ''
            ? COLUMN_STATS_EMPTY.toLowerCase().includes(searchValue.toLowerCase())
            : rowValue.toLowerCase().includes(searchValue.toLowerCase());
    }

    private doesSearchMatchPath(path: string, searchValue: string): boolean {
        return path.toLowerCase().includes(searchValue.toLowerCase());
    }

    addExportFieldsClicked(): void {
        const categoryValues = this.gridApi.getSelectedRows().map<string>((value: ColumnStatsParentRowData) => {
            return value[ColumnStatsColumnEnum.UniqueValue].value;
        }).join(',');

        const filterValues = this.dvApiRequestFormatterService.formatFilterRequestParam(this.conditions.value);

        const params: UniqueAttributesRequest = {
            marketplace: this.country.marketplace,
            include_category_values: categoryValues,
            filter_by_column_type: filterValues
        };

        this.fdxBlockUIService.start();

        this.categoryTaxonomyDataService.getUniqueAttributes(this.databaseId, params)
            .pipe(
                catchError(() => {
                    this.fdxBlockUIService.stop();
                    this.fdxUI.showToastError('Something went wrong getting unique attributes for categories.');
                    return throwError(() => new FdxError('UNKNOWN_ERROR', 'Error retrieving data'));
                }),
                tap((response: UniqueAttributeField[]) => {
                    // If the length of the API data response is empty, or no new fields would be added, don't close the offcanvas

                    let newFields: ExportField[] = [];
                    if (response.length > 0) {
                        newFields = response.filter((field: UniqueAttributeField) => {
                            return !this.exportFields.find((exportField) => exportField.export_field_name === field.field);
                        }).map<ExportField>((field: UniqueAttributeField) => {
                            return {
                                field_name: '',
                                export_field_name: field.field
                            }
                        });
                    }

                    this.fdxBlockUIService.stop();

                    if (newFields.length === 0) {
                        this.fdxUI.showToastWarning('No additional attributes were found for the selected categories');
                        return;
                    }

                    this.offcanvas.close(newFields);
                }),
                takeUntil(this.unsubscribe$)
            ).subscribe();
    }

    cancelClicked(): void {
        this.onOffcanvasDismissed(null);
    }

    public onGridReady(params: GridReadyEvent<ColumnStatsParentRowData>): void {
        this.gridApi = params.api;

        this.gridApi.setGridOption('columnDefs', this.getColumnDefs());
    }

    public onRowDataUpdated(_event: RowDataUpdatedEvent): void {
        this.gridApi?.autoSizeColumns(this.columnsToAutosize);
        this.gridApi?.selectAll();
    }

    public onOffcanvasDismissed(reason: unknown): void {
        this.offcanvas.dismiss(reason);
    }

    ngOnDestroy(): void {
        this.rowData$.complete();
        this.unsubscribe$.next();
        this.unsubscribe$.complete();
    }
}
