import AppStateService from '@ajs/services/AppStateService';
import { inject } from '@angular/core';
import { CanActivateFn, Router } from '@angular/router';
import { AccountFeatureFlag } from '@app/core/models/enums/account-feature-flag.enum';
import { UserFeatureFlag } from '@app/core/models/enums/user-feature-flag.enum';
import { LinkService } from '@app/core/services/link.service';
import { combineLatest, filter, map, take } from 'rxjs';

export function redirectOnUserFeatureFlag(
    flagNames: UserFeatureFlag[],
    redirectRoute: keyof LinkService
): CanActivateFn {
    return () => {
        const appStateService: AppStateService = inject(AppStateService);
        const linkService: LinkService = inject(LinkService);
        const router: Router = inject(Router);

        return combineLatest([
            appStateService.userLoaded$,
            appStateService.databaseLoaded$
        ]).pipe(
            filter(([userLoaded, dbLoaded]) => Boolean(userLoaded) && Boolean(dbLoaded)),
            take(1),
            map(() => {
                const redirectAllowed = flagNames.every((name) => appStateService.getUser().hasFeatureEnabled(name));
                let linkServiceTarget = linkService[redirectRoute];
                // fallthrough route for any strange keys on linkService.  Generally use the flag to tell
                // whether to go to the original page or block.
                if (typeof linkServiceTarget === 'function') {
                    return !redirectAllowed;
                }
                if (typeof linkServiceTarget === 'string') {
                    linkServiceTarget = [linkServiceTarget];
                }
                if (redirectAllowed) {
                    return router.createUrlTree(linkServiceTarget);
                }
                return true;
            })
        );
    };
}

export function redirectOnFailedUserFeatureFlag(
    flagNames: UserFeatureFlag[],
    redirectRoute: keyof LinkService
): CanActivateFn {
    return () => {
        const appStateService: AppStateService = inject(AppStateService);
        const router: Router = inject(Router);
        const linkService: LinkService = inject(LinkService);

        return combineLatest([
            appStateService.userLoaded$,
            appStateService.databaseLoaded$
        ]).pipe(
            filter(([userLoaded, dbLoaded]) => Boolean(userLoaded) && Boolean(dbLoaded)),
            take(1),
            map(() => {
                const originalAllowed = !flagNames.every((name) => appStateService.getUser().hasFeatureEnabled(name));
                let linkServiceTarget = linkService[redirectRoute];

                // fallthrough route for any strange keys on linkService.  Generally use the flag to tell
                // whether to go to the original page or block.
                if (typeof linkServiceTarget === 'function') {
                    return originalAllowed;
                }
                if (typeof linkServiceTarget === 'string') {
                    linkServiceTarget = [linkServiceTarget];
                }
                if (originalAllowed) {
                    return true;
                }
                return router.createUrlTree(linkServiceTarget);
            })
        );
    };
}

export function redirectOnAccountFeatureFlag(
    flagNames: AccountFeatureFlag[],
    redirectRoute: keyof LinkService
): CanActivateFn {
    return () => {
        const appStateService: AppStateService = inject(AppStateService);
        const linkService: LinkService = inject(LinkService);
        const router: Router = inject(Router);

        return combineLatest([
            appStateService.userLoaded$,
            appStateService.databaseLoaded$
        ]).pipe(
            filter(([userLoaded, dbLoaded]) => Boolean(userLoaded) && Boolean(dbLoaded)),
            take(1),
            map(() => {
                const redirectAllowed = flagNames.every((name) => appStateService.getAccount().hasFeatureEnabled(name));
                let linkServiceTarget = linkService[redirectRoute];

                // fallthrough route for any strange keys on linkService.  Generally use the flag to tell
                // whether to go to the original page or block.
                if (typeof linkServiceTarget === 'function') {
                    return redirectAllowed;
                }
                if (typeof linkServiceTarget === 'string') {
                    linkServiceTarget = [linkServiceTarget];
                }
                if (redirectAllowed) {
                    return router.createUrlTree(linkServiceTarget);
                }
                return true;
            })
        );
    };

}

export function redirectOnFailedAccountFeatureFlag(
    flagNames: AccountFeatureFlag[],
    redirectRoute: keyof LinkService
): CanActivateFn {
    return () => {
        const appStateService: AppStateService = inject(AppStateService);
        const linkService: LinkService = inject(LinkService);
        const router: Router = inject(Router);

        return combineLatest([
            appStateService.userLoaded$,
            appStateService.databaseLoaded$
        ]).pipe(
            filter(([userLoaded, dbLoaded]) => Boolean(userLoaded) && Boolean(dbLoaded)),
            take(1),
            map(() => {
                const originalAllowed = !flagNames.every((name) => appStateService.getAccount().hasFeatureEnabled(name));
                let linkServiceTarget = linkService[redirectRoute];

                // fallthrough route for any strange keys on linkService.  Generally use the flag to tell
                // whether to go to the original page or block.
                if (typeof linkServiceTarget === 'function') {
                    return originalAllowed;
                }
                if (typeof linkServiceTarget === 'string') {
                    linkServiceTarget = [linkServiceTarget];
                }
                if (originalAllowed) {
                    return true;
                }
                return router.createUrlTree(linkServiceTarget);
            })
        );
    };

}
