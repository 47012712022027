import { Component, Input } from '@angular/core';
import { NavGroup } from '@app/modules/nav/components/app-vertical-menu/app-vertical-menu.component';
import { NavStateService } from '@app/modules/nav/services/nav-state.service';
import { IconDefinition } from '@fortawesome/fontawesome-svg-core';
import { faArrowUpRightFromSquare } from '@fortawesome/pro-solid-svg-icons';

@Component({
    selector: 'fdx-app-vertical-menu-collapsed',
    templateUrl: './app-vertical-menu-collapsed.component.html',
    styleUrls: ['./app-vertical-menu-collapsed.component.scss', '../app-vertical-menu/app-vertical-menu-buttons.scss']
})
export class AppVerticalMenuCollapsedComponent {
    @Input() navGroups: NavGroup[] = [];

    protected readonly faArrowUpRightFromSquare: IconDefinition = faArrowUpRightFromSquare;

    constructor(
        protected readonly navStateService: NavStateService,
    ) { }

    getNavGroupClass(id: string): string {
        return this.isNavGroupSelected(id)
            ? 'selected-item'
            : 'unselected-item';
    }

    getDropdownClasses(id: string, isGroup: boolean): string {
        return this.isDropdownSelected(id, isGroup)
            ? 'selected-item active-selected-item'
            : 'unselected-item';
    }

    /**
     * The nav group is included in the dropdown. The nav group is only selected if no sub nav menu items are selected.
     * @param id
     * @param isGroup
     */
    isDropdownSelected(id: string, isGroup: boolean): boolean {
        if (isGroup) {
            return this.navStateService.isNavGroupSelected(id) && this.navStateService.isSubNavUnset();
        }

        return this.navStateService.isSubNavSelected(id);
    }

    /**
     * Unlike the expanded view - display as selected if the nav group OR subnav items have been selected
     * @param id
     */
    isNavGroupSelected(id: string): boolean {
        return this.navStateService.isNavGroupSelected(id) || this.navStateService.isSubNavSelected(id);
    }
}
