import { KeyValue } from '@angular/common';
import { Component } from '@angular/core';
import { ChannelOptionsType } from '@app/analyze-data/types/channel-options.type';
import { AmazonCategory } from '@app/category-taxonomy/models/enums/amazon-category.enum';
import { CategoryPath } from '@app/category-taxonomy/services/responses/category-paths.response';
import { BootstrapThemeProp } from '@app/core/services/bootstrap-theme.service';
import { Modal } from '@app/modules/modals/modals.module';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

export interface CategoryPathsModalParams {
    channel: ChannelOptionsType;
    categories: Record<string, CategoryPath[]>;
    amazonCategoryType?: AmazonCategory;
}

@Component({
    selector: 'fdx-category-paths-modal',
    templateUrl: './category-paths-modal.component.html',
    styleUrls: ['./category-paths-modal.component.scss']
})
export class CategoryPathsModalComponent implements Modal {

    modalTheme: BootstrapThemeProp = 'secondary';
    channel: ChannelOptionsType;
    categories: Record<string, CategoryPath[]>;
    amazonCategoryType?: AmazonCategory;

    get title(): string {
        return `Corresponding ${this.channel?.display} paths`;
    }

    get filterByProductType(): boolean {
        return this.amazonCategoryType === AmazonCategory.ProductType;
    }

    constructor(
        private readonly modal: NgbActiveModal
    ) {}

    onModalInit(params: CategoryPathsModalParams): void {
        this.channel = params.channel;
        this.categories = params.categories;
        this.amazonCategoryType = params.amazonCategoryType;
    }

    onModalSubmit(_event: MouseEvent): void {
        this.modal.close();
    }

    onModalCancel(_event: MouseEvent): void {
        this.modal.dismiss();
    }

    trackByKey(_index: number, path: KeyValue<string, unknown>): string {
        return path.key;
    }
}
