import { Injectable, OnDestroy } from '@angular/core';
import { ChannelOptionsType } from '@app/analyze-data/types/channel-options.type';
import { BehaviorSubject, Observable } from 'rxjs';

@Injectable({
    providedIn: null
})
export class ChannelInputStateService implements OnDestroy {

    private readonly _channels: BehaviorSubject<ChannelOptionsType[] | null> = new BehaviorSubject<ChannelOptionsType[] | null>(null);
    get channels(): ChannelOptionsType[] {
        return this._channels.value;
    }
    set channels(channels: ChannelOptionsType[]) {
        this._channels.next(channels);
    }
    public readonly channels$: Observable<ChannelOptionsType[]> = this._channels.asObservable();

    ngOnDestroy(): void {
        this._channels.complete();
    }
}
