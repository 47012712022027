import { Injectable } from '@angular/core';
import { LogService } from '@app/core/services/log.service';
import { Export } from '@app/exports/services/responses/get-exports.response';

@Injectable({
    providedIn: 'root'
})
export class EDRTSUtilsService {

    static readonly EBS_WHITELISTED_EXPORT_WEBHOOK_CLIENTS: ReadonlySet<string> = new Set<string>(
        [
            'GoogleRegionAvailabilityFeed',
            'FacebookProductFeedFTPWithoutDisable',
            'AmazonSellingPartnerProductFeed',
            'AmazonSellingPartnerInventoryLoader',
            'AmazonSellingPartnerInventoryAndPrice',
            'AmazonTodayInventoryFeed',
            'WalmartProductFeedV4',
            'WalmartPricesFeed',
            'WalmartInventoryFeed',
            'WalmartFacilitiesFeed',
            'WalmartBulkInventoryFeed',
            'eBayAPIProductFeed',
            'EbayAPIPriceInventoryFeed',
            'TargetProductFeedWithoutDisable',
            'TargetUpdateQuantityAndPrice',
            'TargetUpdateQuantity',
            'TargetUpdatePrice',
            'MiraklExportProducts',
            'MiraklExportProductsOffers',
            'MiraklExportOffers',
            'TikTokPriceInventoryFeed',
            'WalmartDisableFeedV4',
            'WalmartPromotionFeed',
            'WalmartSetupByMatchV4',
            'WalmartWFSItemConversion',
            'WalmartWFSItemSetup',
            'WalmartSkuNodeTemplateAssociationFeed',
            'eBayAPIEndListingsFeed',
            'eBayPromotionListingsFeed',
            'eBayAPIAdsPromotedListingsFeed',
            'TargetDisableProducts',
            'TargetUpdateExternalId',
            'TargetUpdateLogistics',
            'MiraklDeleteProductsOffers',
            'MiraklDeleteOffers',
            'TikTokDeleteFeed'
        ]
    );

    constructor(
        private readonly logService: LogService
    ) { }

    /**
     * Returns if this is a valid export to show in the Export Triggers page for an EBS database.
     * Checks that the `protocol` is `'webhook'` and the `protocol_info.http_body.client` is whitelisted.
     * @param e Export
     * @returns boolean
    */
    isValidExportForEBS(e: Export): boolean {
        // sanity check - export should be truthy
        if (!e) {
            return false;
        }

        // check protocol
        switch (e.protocol) {
            // show export for ftp, sftp, ftps
            case 'ftp':
            case 'sftp':
            case 'ftps':
                return true;

            // continue webhook checks below
            case 'webhook':
                break;

            // hide unknown export protocols
            default:
                this.logService.warn('[ExportTriggersPage:filterValidExportsForEBS] Unknown export protocol:', e.protocol, e);
                return false;
        }

        // this is a webhook export

        // parse json of http body string
        const parsed: Record<string, string | number | boolean> = this.parseHTTPBody(e);

        if (!parsed) {
            return false;
        }

        // no client defined in body - still show this export for testing and demos
        // https://feedonomics.atlassian.net/browse/FP-9307?focusedCommentId=142234
        if (!('client' in parsed)) {
            return true;
        }

        // client is supposed to be a string
        if (typeof (parsed.client) !== 'string') {
            return false;
        }

        // check if this is a whitelisted client
        // https://feedonomics.atlassian.net/browse/FP-9307?focusedCommentId=141641
        if (EDRTSUtilsService.EBS_WHITELISTED_EXPORT_WEBHOOK_CLIENTS.has(parsed.client)) {
            return true;
        }

        // some clients require additional conditions
        switch (parsed.client) {
            case 'GoogleProductFeed':
                return parsed.partial_update === true;

            case 'TikTokProductFeedv2':
                return parsed.disable_listings === false;

            default:
                return false;
        }
    }

    public getWebhookClient(e: Export): string {

        const parsed: Record<string, string | number | boolean> = this.parseHTTPBody(e);

        if (typeof (parsed?.client) !== 'string') {
            return null;
        }
        
        return parsed.client;
    }

    private parseHTTPBody(e: Export): Record<string, string | number | boolean> {
        // get protocol info > http body
        let body = e.protocol_info?.http_body;

        // must have http body defined
        if (!body) {
            return null;
        }

        // unquoted feedonomics::data_url::json can break JSON.parse
        // https://feedonomics.atlassian.net/browse/FP-9667
        body = body.replace(/"?feedonomics::data_url::json"?/g, '"feedonomics::data_url::json"');

        try {
            return JSON.parse(body) as Record<string, string | number | boolean>;
        } catch (error) {
            this.logService.warn('[edrtsService:parseHTTPBody] Failed to json parse webhook http body:', error);
            return null;
        }
    }
}
