import { Component, Input } from '@angular/core';

@Component({
    selector: 'fdx-page-subtitle',
    templateUrl: './page-subtitle.component.html',
    styleUrls: ['./page-subtitle.component.scss']
})
export class PageSubtitleComponent {
    @Input() classes: Record<string, boolean> = null;
}
