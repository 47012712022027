// AngularJS Upgraded Services
import { $rootScopeProvider, $transitionsProvider, AppStateServiceProvider, CSVServiceProvider, DatabasesDataServiceProvider, DataDownloadServiceProvider, ExportsDataServiceProvider, fdxUIServiceProvider, fdxUtilsServiceProvider, ImpersonateUserServiceProvider, LogServiceProvider, NavigationServiceProvider, ResolversServiceProvider, uiRouterStateParamsProvider, uiRouterStateProvider } from '@ajs/ajs-upgraded-providers';
import moduleName from '@ajs/app.ajs';
import { DecimalPipe } from '@angular/common';
import { HTTP_INTERCEPTORS, provideHttpClient, withInterceptorsFromDi, withXsrfConfiguration } from '@angular/common/http';
import { DoBootstrap, NgModule } from '@angular/core';
import { BrowserModule, REMOVE_STYLES_ON_COMPONENT_DESTROY } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { NavigationCancel, NavigationEnd, NavigationError, NavigationStart, Router } from '@angular/router';
import { UpgradeModule } from '@angular/upgrade/static';
import { AppRoutingModule } from '@app/app-routing.module';

// Interceptors
import { HttpXsrfWithGetInterceptor } from '@app/core/interceptors/http-xsrf-with-get.interceptor';

// Modules
import { AlertModule } from '@app/modules/alerts/alert.module';
import { InputsModule } from '@app/modules/inputs/inputs.module';
import { ModalsModule } from '@app/modules/modals/modals.module';
import { NavModule } from '@app/modules/nav/nav.module';
import { OffcanvasModule } from '@app/modules/offcanvas/offcanvas.module';
import { StyleWrapperComponent } from '@app/standalone-components/style-wrapper/style-wrapper.component';

import { TransformersModule } from '@app/transformers/transformers.module';
import { environment } from '@environments/environment';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { icon } from '@fortawesome/fontawesome-svg-core';
import { faArrowUpArrowDown } from '@fortawesome/pro-solid-svg-icons';

// AG Grid
import { provideGlobalGridOptions } from 'ag-grid-community';
import { LicenseManager } from 'ag-grid-enterprise';
import { BlockUIModule } from 'ng-block-ui';
import { AppComponent } from './app.component';

LicenseManager.setLicenseKey(environment.agGridKey);

provideGlobalGridOptions({
    theme: 'legacy',
    icons: {
        // globally overwrite the unsort icon to a custom icon
        sortUnSort: `${icon(faArrowUpArrowDown).html.join('')}`
    }
});

/**
 * Stopgap to prevent bugs with ngRouteWorkaround method below.
 * TODO: Remove after the Angular upgrade is complete.
 */
let routeChangeHandledByAngular: boolean = false;

// eslint-disable-next-line angular/window-service
window.addEventListener(
    'popstate',
    () => {
        routeChangeHandledByAngular = true;
    }
);

@NgModule({
    declarations: [
        AppComponent
    ],
    imports: [
        AlertModule,
        AppRoutingModule,
        BlockUIModule.forRoot(),
        BrowserAnimationsModule,
        BrowserModule,
        FontAwesomeModule,
        InputsModule,
        ModalsModule,
        NavModule,
        OffcanvasModule,
        StyleWrapperComponent,
        TransformersModule,
        UpgradeModule
    ],
    providers: [
        DecimalPipe,
        provideHttpClient(
            withInterceptorsFromDi(),
            withXsrfConfiguration(
                {
                    headerName: HttpXsrfWithGetInterceptor.XSRF_HEADER_NAME
                }
            )
        ),
        {
            provide: HTTP_INTERCEPTORS,
            useClass: HttpXsrfWithGetInterceptor,
            multi: true
        },
        {
            provide: REMOVE_STYLES_ON_COMPONENT_DESTROY,
            useValue: false
        },
        $rootScopeProvider,
        $transitionsProvider,
        AppStateServiceProvider,
        CSVServiceProvider,
        DatabasesDataServiceProvider,
        DataDownloadServiceProvider,
        ExportsDataServiceProvider,
        fdxUIServiceProvider,
        fdxUtilsServiceProvider,
        ImpersonateUserServiceProvider,
        LogServiceProvider,
        NavigationServiceProvider,
        ResolversServiceProvider,
        uiRouterStateParamsProvider,
        uiRouterStateProvider
    ]
})
export class AppModule implements DoBootstrap {
    constructor(
        private readonly upgrade: UpgradeModule,
        private readonly router: Router
    ) { }

    public ngDoBootstrap(): void {
        this.upgrade.bootstrap(
            document.documentElement,
            [
                moduleName
            ],
            {
                strictDi: true
            }
        );

        // setup @angular/route (Angular 14) and ngRoute (AngularJS) integration
        this.ngRouteWorkaround();
    }

    /**
     * Workaround for the Angular router.
     * TODO: Remove after the Angular upgrade is complete.
     */
    private ngRouteWorkaround(): void {
        this.router.events
            .subscribe(
                (event) => {
                    if (event instanceof NavigationStart) {
                        routeChangeHandledByAngular = true;
                        return;
                    }

                    if (
                        event instanceof NavigationEnd ||
                        event instanceof NavigationCancel ||
                        event instanceof NavigationError
                    ) {
                        // eslint-disable-next-line angular/timeout-service
                        setTimeout(
                            () => {
                                routeChangeHandledByAngular = false;
                            },
                            10
                        );
                    }
                }
            );

        /**
         * Fix for Angular router not updating when clicking on AngularJS links.
         */
        // eslint-disable-next-line
        this.upgrade.$injector
            .get('$rootScope')
            .$on(
                '$locationChangeStart',
                // eslint-disable-next-line
                (_: any, next: string, __: string) => {
                    if (routeChangeHandledByAngular) {
                        return;
                    }

                    const hashIdx = next.indexOf('#');
                    const pathAfterHash = next.substring(hashIdx + 1);

                    void this.router.navigateByUrl(pathAfterHash, { skipLocationChange: true });
                }
            );

        this.router.initialNavigation();
    }
}
