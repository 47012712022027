import { Component, ElementRef, EventEmitter, HostListener, Input, OnInit, Output, TemplateRef } from '@angular/core';
import { ContainerService } from '@app/core/services/container.service';
import { AdditionalOffcanvasDismissReasons } from '@app/modules/offcanvas/enums/additional-offcanvas-dismiss-reasons';
import { OffcanvasPanelSizeEnum } from '@app/modules/offcanvas/enums/offcanvas-panel-size.enum';
import { OffcanvasTheme } from '@app/modules/offcanvas/enums/offcanvas-theme.enum';
import { ClassInput } from '@feedonomics/frontend-components';
import {
    IconDefinition,
    faArrowDownLeftAndArrowUpRightToCenter,
    faArrowUpRightAndArrowDownLeftFromCenter,
    faWindowMinimize
} from '@fortawesome/pro-solid-svg-icons';

@Component({
    selector: 'offcanvas-hoc',
    templateUrl: './offcanvas-hoc.component.html',
    styleUrls: ['./offcanvas-hoc.component.scss']
})
export class OffcanvasHocComponent implements OnInit {
    @Input() public offcanvasTheme: OffcanvasTheme = OffcanvasTheme.Default;
    @Input() public offcanvasTitle: string = 'Offcanvas Title';
    @Input() public offcanvasTitleIcon?: IconDefinition;
    @Input() public headerAddon: TemplateRef<any>;
    @Input() public panelClassWidth: string = 'w-60';
    @Input() public panelMinWidth?: number;
    @Input() public closeDisabled: boolean = false;
    @Input() public bodyClasses: ClassInput = {};
    @Input() public footerClasses: ClassInput = {};
    @Input() public enableMinimize: boolean = false;
    @Input() public enableFullscreen: boolean = true;

    @Output() public readonly offcanvasDismissed: EventEmitter<AdditionalOffcanvasDismissReasons> = new EventEmitter<AdditionalOffcanvasDismissReasons>();
    @Output() public readonly offcanvasMinimized: EventEmitter<void> = new EventEmitter<void>();
    @Output() public readonly offcanvasBackdropClick: EventEmitter<void> = new EventEmitter<void>();

    showGoFullscreenIcon: boolean = true;
    container: HTMLElement;

    readonly offcanvasPanelSizeEnum: typeof OffcanvasPanelSizeEnum = OffcanvasPanelSizeEnum;
    readonly minimizeIcon: IconDefinition = faWindowMinimize;
    readonly goFullScreenIcon: IconDefinition = faArrowUpRightAndArrowDownLeftFromCenter;
    readonly exitFullScreenIcon: IconDefinition = faArrowDownLeftAndArrowUpRightToCenter;

    public get theme(): typeof OffcanvasTheme {
        return OffcanvasTheme;
    }

    public get offcanvasHeaderClasses(): string {
        return `fdx-header-bg-${this.offcanvasTheme}`;
    }

    @HostListener('window:click', ['$event'])
    windowClicked($event: PointerEvent): void {
        const backdrop: EventTarget = this.container.querySelector('ngb-offcanvas-backdrop');

        // If the backdrop was one of the elements that was clicked (should only be one anyway), emit the event
        if ($event.composedPath().some((element) => element === backdrop)) {
            this.offcanvasBackdropClick.emit();
        }
    }

    constructor(
        private readonly containerService: ContainerService,
        private readonly elementRef: ElementRef<HTMLElement>
    ) { }

    ngOnInit(): void {
        this.container = this.containerService.getContainer();

        const panel = this.getOffcanvasPanel();

        panel.classList.add(this.panelClassWidth);

        if (typeof (this.panelMinWidth) === 'number') {
            panel.style.minWidth = `${this.panelMinWidth}px`;
        }
    }

    public onOffcanvasPanelSizeUpdate(offcanvasPanelSize: OffcanvasPanelSizeEnum): void {
        this.showGoFullscreenIcon = offcanvasPanelSize !== OffcanvasPanelSizeEnum.FullScreen;

        const panel = this.getOffcanvasPanel();

        switch (offcanvasPanelSize) {
            case OffcanvasPanelSizeEnum.FullScreen:
                panel.classList.add('w-100');
                panel.classList.remove(this.panelClassWidth);
                break;

            default:
                panel.classList.add(this.panelClassWidth);
                panel.classList.remove('w-100');
                break;
        }
    }

    private getOffcanvasPanel(): HTMLElement {
        // Grab an element reference to the offcanvas panel to can modify the class width
        return this.elementRef.nativeElement.closest('ngb-offcanvas-panel');
    }

    public dismissOffcanvasWithX(): void {
        this.offcanvasDismissed.emit(AdditionalOffcanvasDismissReasons.X_CLICK);
    }
}
