import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { InputsModule } from '@app/modules/inputs';
import { AdvancedQueryComponent } from '@app/modules/query/components/advanced-query/advanced-query.component';
import { BasicQueryInnerComponent } from '@app/modules/query/components/basic-query-inner/basic-query-inner.component';
import { BasicQueryRowComponent } from '@app/modules/query/components/basic-query-row/basic-query-row.component';
import { BasicQueryComponent } from '@app/modules/query/components/basic-query/basic-query.component';
import { QueryButtonsComponent } from '@app/modules/query/components/query-buttons/query-buttons.component';
import { QueryInputsComponent } from '@app/modules/query/components/query-inputs/query-inputs.component';
import { QueryTypeButtonsComponent } from '@app/modules/query/components/query-type-buttons/query-type-buttons.component';
import { TransformerInputsComponent } from '@app/modules/query/components/transformer-inputs/transformer-inputs.component';
import { SpinnerComponent } from '@app/standalone-components/spinner/spinner.component';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { NgbTooltipModule } from '@ng-bootstrap/ng-bootstrap';
import { NgSelectModule } from '@ng-select/ng-select';

@NgModule({
    declarations: [
        AdvancedQueryComponent,
        BasicQueryComponent,
        BasicQueryInnerComponent,
        BasicQueryRowComponent,
        QueryButtonsComponent,
        QueryInputsComponent,
        QueryTypeButtonsComponent,
        TransformerInputsComponent
    ],
    imports: [
        CommonModule,
        FontAwesomeModule,
        InputsModule,
        NgbTooltipModule,
        NgSelectModule,
        ReactiveFormsModule,
        SpinnerComponent
    ],
    exports: [
        AdvancedQueryComponent,
        QueryButtonsComponent,
        QueryInputsComponent,
        QueryTypeButtonsComponent,
        TransformerInputsComponent
    ]
})
export class QueryModule { }
