import { Pipe, PipeTransform } from '@angular/core';

@Pipe({name: 'escapeHtml'})
export class EscapeHtmlPipe implements PipeTransform {
    entityMap: Record<string, string> = {
        '&': '&amp;',
        '<': '&lt;',
        '>': '&gt;',
        '"': '&quot;',
        '\'': '&#39;',
        '/': '&#x2F;'
    };
    public transform(value: string): string {
        return String(value).replace(/[&<>"'/]/g, (s): string => {
            return this.entityMap[s];
        });
    }
}
