import AppStateService from '@ajs/services/AppStateService';
import FdxUI from '@ajs/services/fdxUI';
import { Component, OnDestroy, OnInit, Self } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { AppLayout } from '@app/core/models/enums/app-layout.enum';
import { AnalyticsService } from '@app/core/services/analytics.service';
import { AppLayoutService } from '@app/core/services/app-layout.service';
import { BigCommerceSelfServiceState } from '@app/core/services/bigcommerce-self-service-state.service';

import { KeyboardShortcutService } from '@app/keyboard-shortcuts/services/keyboard-shortcut.service';
import { NgbPopoverConfig, NgbTooltipConfig } from '@ng-bootstrap/ng-bootstrap';
import { Subject, takeUntil, tap } from 'rxjs';
import { LinkService } from './core/services/link.service';

@Component({
    selector: 'fdx-root',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.scss'],
    providers: [
        AppLayoutService
    ]
})
export class AppComponent implements OnInit, OnDestroy {
    public viewHeight: boolean = false;

    private readonly unsubscribe$: Subject<void> = new Subject<void>();

    get showEbayUI(): boolean {
        return this.fdxUI.showMinimalEbayUI();
    }

    get isAccountsUrl(): boolean {
        const accountsUrl = this.linkService.accountsLink.reduce((accumulator, currentString) => {
            return accumulator + currentString;
        }, '');

        return this.router.url === accountsUrl;
    }



    constructor(
        @Self() private readonly appLayoutService: AppLayoutService,
        private readonly analyticsService: AnalyticsService,
        protected readonly appStateService: AppStateService,
        protected readonly bigcommerceSelfService: BigCommerceSelfServiceState,
        private readonly fdxUI: FdxUI,
        private readonly keyboardShortcut: KeyboardShortcutService,
        private readonly linkService: LinkService,
        private readonly ngbTooltipConfig: NgbTooltipConfig,
        private readonly ngbPopoverConfig: NgbPopoverConfig,
        private readonly router: Router
    ) { }

    ngOnInit(): void {
        this.router.events
            .pipe(
                tap((event) => {
                    if (event instanceof NavigationEnd) {
                        this.analyticsService.identify();
                    }
                }),
                takeUntil(this.unsubscribe$)
            )
            .subscribe();

        this.appLayoutService.layout$
            .pipe(
                tap((layout) => {
                    this.viewHeight = layout === AppLayout.ViewHeight;
                }),
                takeUntil(this.unsubscribe$)
            )
            .subscribe();

        this.setTooltipsDefaultConfig();
        this.setPopoversDefaultConfig();
        this.keyboardShortcut.init();

    }

    ngOnDestroy(): void {
        this.unsubscribe$.next();
        this.unsubscribe$.complete();
    }

    private setTooltipsDefaultConfig(): void {
        this.ngbTooltipConfig.openDelay = 500;
    }

    private setPopoversDefaultConfig(): void {
        this.ngbPopoverConfig.openDelay = 500;
    }

}
