import { AfterViewInit, Component, ContentChild, ElementRef, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { isEmpty } from '@app/core/functions/isEmpty';
import { LogService } from '@app/core/services/log.service';
import { BaseInputComponent } from '@app/modules/inputs/components/base-input/base-input.component';
import { CodeInputComponent } from '@app/modules/inputs/components/code-input/code-input.component';
import { ExportSelectComponent } from '@app/modules/inputs/components/export-select/export-select.component';
import { InputsUtilitiesService } from '@app/modules/inputs/services/inputs-utilities.service';
import { NgbDateParserFormatter } from '@ng-bootstrap/ng-bootstrap';
import { NgSelectComponent } from '@ng-select/ng-select';
import { isArray } from 'angular';
import { debounceTime, distinctUntilChanged, takeUntil, tap } from 'rxjs';

@Component({
    selector: 'fdx-input-wrapper',
    providers: [{
        provide: BaseInputComponent,
        useExisting: InputWrapperComponent
    }],
    templateUrl: './input-wrapper.component.html',
    styleUrls: ['./input-wrapper.component.scss']
})
export class InputWrapperComponent extends BaseInputComponent implements OnInit, OnChanges, AfterViewInit {

    public customElement: HTMLElement;              // Get the custom element in the template

    @ContentChild(NgSelectComponent) ngSelect?: NgSelectComponent;                          // Potential NgSelectComponent that's within the ng-content
    @ContentChild(NgSelectComponent, { read: ElementRef }) ngSelectRef?: ElementRef<HTMLElement>;          // Potential ElementRef of an NgSelectComponent that's within the ng-content

    @ContentChild(ExportSelectComponent) exportSelect?: ExportSelectComponent;              // Potential ExportSelectComponent that's within the ng-content
    @ContentChild(ExportSelectComponent, { read: ElementRef }) exportSelectRef?: ElementRef<HTMLElement>;  // Potential ElementRef of an ExportSelectComponent that's within the ng-content

    @ContentChild(CodeInputComponent) fdxCodeInput?: CodeInputComponent;                    // Potential CodeInputComponent that's within the ng-content
    @ContentChild(CodeInputComponent, { read: ElementRef }) fdxCodeInputRef?: ElementRef<HTMLElement>;     // Potential ElementRef of an CodeInputComponent that's within the ng-content

    constructor(
        protected readonly element: ElementRef<HTMLElement>,
        protected readonly inputsUtilitiesService: InputsUtilitiesService,
        protected readonly logService: LogService,
        protected readonly ngbDateParserFormatter: NgbDateParserFormatter
    ) {
        super(element, inputsUtilitiesService, logService, ngbDateParserFormatter);
    }

    ngOnInit(): void {
        if (this.classes['form-control'] === undefined) {
            this.initClasses['form-control'] = true;
        }
    }

    ngOnChanges(changes: SimpleChanges): void {
        if (changes.readonly && (this.ngSelect || this.exportSelect)) {
            this.updateNgSelectDisabledClass();
        }
    }

    ngAfterViewInit(): void {
        super.ngAfterViewInit();

        if (!this.customElement) {
            if (this.ngSelect) {
                this.setupNgSelect();
            } else if (this.exportSelect) {
                this.setupExportSelect();
            } else if (this.fdxCodeInput) {
                this.setupCodeInput();
            }
        }

        if (this.customElement && this.ariaLabel) {
            this.customElement.setAttribute('aria-label', this.labelText);
        }

        this.validateConfiguration();

        this.control.statusChanges.pipe(
            takeUntil(this.unsubscribe$)
        ).subscribe({
            next: () => {
                this.updateNgSelectDisabledClass();
            }
        });
    }

    setDisabled(isDisabled: boolean): void {
        super.setDisabled(isDisabled);
        this.updateNgSelectDisabledClass();
    }

    get customElementId(): string {
        return this.customElement ? this.customElement.getAttribute('id') : null;
    }

    private validateConfiguration(): string[] {
        const errors: string[] = [];

        if (this.customElementId === null && !this.exportSelect) {
            errors.push(`${this.controlName} missing ID: Binding an id to the input within an fdx-input-wrapper is required`);
        }

        /**
         * todo: uncomment this validation, update components using the input-wrapper with ng-select to make sure that the ng-select id matches the form-field componentName, and update unit tests
         *  This will ensure that the ng-select is correctly associated with the outer label's "for" property
         *  See Jira Ticket for details: https://feedonomics.atlassian.net/browse/FP-6091
         */
        // if (this.customElementId !== this.controlName) {
        //     errors.push(`fdx-form-field controlName "${this.controlName}" does not match custom element id "${this.customElementId}"`)
        // }

        if (this.ngSelect?.multiple && !this.ngSelect.labelTemplate) {
            errors.push('multiselect ng-select should use SelectMultipleLabelTemplateComponent styling, refer to the inputs-test-page for examples on how to incorporate this template');
        }

        errors.forEach((error) => {
            this.logService.error(error);
        });

        return errors;
    }

    updateNgSelectDisabledClass(): void {
        if (this.control.disabled || this.readonly) {    // Have disabled state match control, not input
            this.classes['is-disabled'] = true;
        } else {
            this.classes['is-disabled'] = false;
        }
    }

    setupNgSelect(): void {
        this.customElement = this.ngSelectRef.nativeElement;
        this.initClasses['form-control-ng-select'] = true;
        this.updateNgSelectDisabledClass();

        this.updateHasValueClass();
        this.control.valueChanges.pipe(
            debounceTime(this.emitDelay),
            distinctUntilChanged(),
            tap(() => this.updateHasValueClass()),
            takeUntil(this.unsubscribe$)
        ).subscribe();
    }

    setupExportSelect(): void {
        this.customElement = this.exportSelectRef.nativeElement;
        this.initClasses['form-control-ng-select'] = true;
        this.updateNgSelectDisabledClass();
    }

    setupCodeInput(): void {
        this.customElement = this.fdxCodeInputRef.nativeElement;
    }

    updateHasValueClass(): void {
        const elPlaceholder = this.customElement?.getAttribute('placeholder');
        if (
            (isEmpty(this.value) || (isArray(this.value) && this.value.length === 0)) &&
            isEmpty(this.placeholder) &&
            isEmpty(elPlaceholder)) {
            this.element.nativeElement.classList.remove('fdx-has-value');
        } else {
            this.element.nativeElement.classList.add('fdx-has-value');  // For use with floating forms
        }
    }

    get inputClasses(): Record<string, boolean> {
        return {
            ...super.inputClasses,
            'is-disabled': this.control.disabled
        };
    }

}
