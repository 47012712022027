import { UIRouterState } from '@ajs/ajs-upgraded-providers';
import { AccountPermissions } from '@ajs/models/account/AccountDataModel';
import AppStateService from '@ajs/services/AppStateService';
import FdxUI from '@ajs/services/fdxUI';
import ImpersonateUserService from '@ajs/services/ImpersonateUserService';
import { Component, HostBinding, Inject, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { WINDOW } from '@app/core/providers/window.provider';
import { DateTimeService } from '@app/core/services/date-time.service';
import { FdxBlockUIService } from '@app/core/services/fdx-block-ui.service';
import { FdxUtilsService } from '@app/core/services/fdx-utils.service';
import { LinkService } from '@app/core/services/link.service';
import { AbstractAppMenuComponent } from '@app/modules/nav/components/abstract-app-menu/abstract-app-menu.controller';
import { NavigationMenuPermissionService } from '@app/modules/nav/services/navigation-menu-permission.service';
import ReleaseNotesService from '@app/release-notes/services/release-notes.service';
import { InvitationsService } from '@app/user/services/invitations.service';
import { UserDataService } from '@app/user/services/user-data.service';
import { IconDefinition } from '@fortawesome/fontawesome-svg-core';
import { faArrowLeftToLine, faArrowRightArrowLeft, faArrowRightFromBracket, faArrowUpRightFromSquare, faChevronRight, faClockRotateLeft } from '@fortawesome/pro-solid-svg-icons';
import { NgbAccordionDirective } from '@ng-bootstrap/ng-bootstrap/accordion/accordion.directive';
import { StateService } from '@uirouter/angularjs';
import { filter, takeUntil, tap } from 'rxjs';

@Component({
    selector: 'fdx-app-vertical-menu',
    templateUrl: './app-vertical-menu.component.html',
    styleUrls: ['./app-vertical-menu.component.scss', './app-vertical-menu-buttons.scss']
})
export class AppVerticalMenuComponent extends AbstractAppMenuComponent implements OnInit {
    @ViewChild('accordion') accordion: NgbAccordionDirective;

    @HostBinding('class.collapsed') isNavBarCollapsed: boolean = false;

    navGroups: NavGroup[] = [];

    protected readonly faChevronRight: IconDefinition = faChevronRight;
    protected readonly faArrowLeftToLine: IconDefinition = faArrowLeftToLine;
    protected readonly faArrowUpRightFromSquare: IconDefinition = faArrowUpRightFromSquare;
    protected readonly faClockRotateLeft: IconDefinition = faClockRotateLeft;
    protected readonly faArrowRightFromBracket: IconDefinition = faArrowRightFromBracket;
    protected readonly faArrowRightArrowLeft: IconDefinition = faArrowRightArrowLeft;

    get logo(): string {
        return '/app/images/logos/feedonomics/feedonomics-logomark-white.svg';
    }

    constructor(
        @Inject(WINDOW) protected readonly window: Window,
        @Inject(UIRouterState) protected readonly $state: StateService,   // Temporary for use with Ebay Selection and User Access Control pages being on AngularJS router
        protected readonly appStateService: AppStateService,
        protected readonly dateTimeService: DateTimeService,
        protected readonly fdxBlockUIService: FdxBlockUIService,
        protected readonly fdxUI: FdxUI,
        protected readonly fdxUtils: FdxUtilsService,
        protected readonly impersonateUserService: ImpersonateUserService,   // TODO: Upgrade to Angular
        protected readonly invitationsService: InvitationsService,
        protected readonly linkService: LinkService,
        private readonly navMenuPermissionsService: NavigationMenuPermissionService,
        protected readonly userDataService: UserDataService,
        protected readonly router: Router,
        private readonly releaseNotesService: ReleaseNotesService
    ) {
        super($state, linkService, appStateService, fdxBlockUIService, fdxUI, fdxUtils, impersonateUserService, invitationsService, userDataService, router, window);
    }

    ngOnInit(): void {
        super.ngOnInit();

        this.appStateService.databaseLoaded$
            .pipe(
                filter((value) => Boolean(value)),
                tap(() => {
                    this.navGroups = this.navMenuPermissionsService.getNavigationGroups();
                }),
                takeUntil(this.unsubscribe$)
            ).subscribe();

        this.appStateService.userLoaded$
            .pipe(
                filter((value) => Boolean(value)),
                tap(() => this.releaseNotesService.checkUnread()),
                takeUntil(this.unsubscribe$)
            ).subscribe();
    }

    onToggleNavVisibility(): void {
        this.isNavBarCollapsed = !this.isNavBarCollapsed;
    }
}

export interface NavItem {
    id: string;
    label: string;
    link: string[];
    isExternalLink?: boolean;
    requiredPermissions: {
        accountFeatureFlags?: string[];
        userFeatureFlags?: string[];
        minAccountPermission?: AccountPermissions;
        /**
         * Additional permission checks go here
         */
        hasPermission?: () => boolean;
    };
}

export interface NavGroup extends NavItem {
    icon: IconDefinition;
    children?: NavItem[];
}
