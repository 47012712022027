import { Injectable, OnDestroy } from '@angular/core';
import { AnalystUser } from '@app/feed-support/services/responses/analyst-users.response';
import { BehaviorSubject, Observable } from 'rxjs';

/**
 * TODO: Make provided in null when fully on Angular 16
 */
@Injectable({
    providedIn: 'root'
})
export class FeedSupportStateService implements OnDestroy {
    private readonly _effectiveUser$: BehaviorSubject<Partial<AnalystUser>> = new BehaviorSubject<Partial<AnalystUser>>(null);

    get effectiveUser$(): Observable<Partial<AnalystUser>> {
        return this._effectiveUser$.asObservable();
    }

    get effectiveUser(): Partial<AnalystUser> {
        return this._effectiveUser$.value;
    }

    set effectiveUser(user: Partial<AnalystUser>) {
        this._effectiveUser$.next(user);
    }

    ngOnDestroy(): void {
        this._effectiveUser$.complete();
    }
}
