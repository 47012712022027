import AppStateService from '@ajs/services/AppStateService';
import FdxUI from '@ajs/services/fdxUI';
import { Component, EventEmitter, Input, OnChanges, OnDestroy, OnInit, Output, SimpleChanges } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { FdxUtilsService } from '@app/core/services/fdx-utils.service';
import { JsonService } from '@app/core/services/json.service';
import { DbFieldModel } from '@app/databases/models/db-field.model';
import { DbModel } from '@app/databases/models/db.model';
import { ExportFieldsOffcanvasComponent, ExportFieldsOffcanvasResolver } from '@app/exports/components/export-fields-offcanvas/export-fields-offcanvas.component';
import { ImportTemplateStringModalComponent } from '@app/exports/components/import-template-string-modal/import-template-string-modal.component';
import { WebhookType } from '@app/exports/data/webhook-type';
import { ExportProtocol } from '@app/exports/enums/export.enums';
import { ExistingExport } from '@app/exports/models/interfaces/existing-export.interface';
import { DbFieldSortable } from '@app/exports/models/types/db-field-sortable.type';
import { PresetOption, presetOptions } from '@app/exports/pages/exports-page/preset-options';
import { AggregatedDbFields, CodeInputService } from '@app/exports/services/code-input.service';
import { ExportColumnType, ExportField } from '@app/exports/services/responses/get-exports.response';
import { GetFtpTriggersResponse } from '@app/ftp-trigger/services/responses/get-ftp-triggers.response';
import { ModalService } from '@app/modules/modals/services/modal.service';
import { OffcanvasService } from '@app/modules/offcanvas/services/offcanvas.service';
import { IconDefinition, faPlus, faQuestionCircle } from '@fortawesome/pro-solid-svg-icons';
import { Subject, takeUntil, tap } from 'rxjs';

@Component({
    selector: 'fdx-export-config-form-new',
    styleUrls: ['export-config-form-new.component.scss'],
    templateUrl: 'export-config-form-new.component.html'
})
export class ExportConfigFormNewComponent implements OnChanges, OnInit, OnDestroy {
    @Input() addExportError: string;
    @Input() dbFields: DbFieldModel[] = [];
    @Input() dbSortableFields: DbFieldSortable[];
    @Input() exportItem: ExistingExport;
    @Input() exportItems;
    @Input() triggers: GetFtpTriggersResponse;
    @Output() readonly addExport: EventEmitter<void> = new EventEmitter<void>();
    @Output() readonly exportChange: EventEmitter<Record<string, any>> = new EventEmitter<Record<string, any>>();
    @Output() readonly exportFieldsChange: EventEmitter<ExportField[]> = new EventEmitter<ExportField[]>();
    @Output() readonly webhookTypeChange: EventEmitter<WebhookType> = new EventEmitter<WebhookType>();

    readonly idPrepend: string = 'newExport-';

    private readonly unsubscribe$: Subject<void> = new Subject<void>();

    aggregatedDbFields: AggregatedDbFields;
    exportFields: ExportField[] = [
        {
            field_name: '',
            export_field_name: '',
            sort_order: 0
        }
    ];
    readonly iconHelp: IconDefinition = faQuestionCircle;
    readonly iconPlus: IconDefinition = faPlus;
    form: UntypedFormGroup;
    presetOptions;
    showAdvancedOptions = false;

    get displayImportDgq(): boolean {
        return !!this.appStateService.getAccount()?.features?.import_dgqs;
    }

    get shouldDisplayAddExportFieldsButton(): boolean {
        return this.exportItem.protocol === ExportProtocol.Webhook;
    }

    private get database(): DbModel {
        return this.appStateService.getDatabase();
    }

    get isEBSDatabase(): boolean {
        return this.database.event_sync === '1';
    }

    constructor(
        private readonly appStateService: AppStateService,
        private readonly codeInputService: CodeInputService,
        private readonly fb: UntypedFormBuilder,
        private readonly fdxUI: FdxUI,
        private readonly fdxUtilsService: FdxUtilsService,
        private readonly jsonService: JsonService,
        private readonly modalService: ModalService,
        private readonly offcanvasService: OffcanvasService
    ) {
        this.presetOptions = this.fdxUtilsService.clone(presetOptions).filter(option => {
            if (option.name === 'ShopStyle SKU Feed (NDJSON)' && this.accountHasFeature('shopstyle_export_template', 'enabled')) {
                option.isVisible = true;
            }
            const rakutenTemplateFlag = (option.name === 'Rakuten - GSP' || option.name === 'Rakuten - Publisher Full' || option.name === 'Rakuten - Publisher Delta');
            if (rakutenTemplateFlag && this.accountHasFeature('rakuten_export_templates', 'enabled')) {
                option.isVisible = true;
            }
            const rakutenTestTemplateFlag = (option.name === 'Rakuten - GSP' || option.name === 'Rakuten - Publisher Full' || option.name === 'Rakuten - Publisher Delta' || option.name === 'Rakuten - Publisher Full - Test' || option.name === 'Rakuten - Publisher Delta - Test');
            if (rakutenTestTemplateFlag && this.accountHasFeature('rakuten_test_export_templates', 'enabled')) {
                option.isVisible = true;
            }
            return option.isVisible;
        });
    }

    ngOnChanges({ exportItem, dbFields }: SimpleChanges): void {
        const didExportItemChange = exportItem?.currentValue && exportItem.currentValue !== exportItem.previousValue;
        const didDbFieldsChange = dbFields?.currentValue && dbFields.currentValue !== dbFields.previousValue;
        const didCopiedFromChange = exportItem?.currentValue?.copied_from && typeof exportItem.currentValue.copied_from !== 'string';

        if (didExportItemChange && exportItem.currentValue.export_fields) {
            this.exportFields = exportItem.currentValue.export_fields;
        }
        if (didDbFieldsChange) {
            this.aggregatedDbFields = this.codeInputService.aggregateDbFields(dbFields.currentValue);
        }

        /**
         * copied_from is originally sent as the full export, so after we patch it into our form, set it to just the ID value
         * so the API can use this value to copy the transformers and overrides. Next change, ensure the type isn't a string
         * so we don't re-enter this if. This is a shoddy way of fixing this, but the whole page is in need of a refactor with
         * proper form and event handling, so I think this is the best approach for now.
         */
        if (didCopiedFromChange) {
            this.form.patchValue({
                copiedFrom: exportItem.currentValue.copied_from
            }, { emitEvent: false });

            this.exportItem.copied_from = exportItem.currentValue.copied_from.id;
        }
    }

    ngOnInit(): void {
        this.form = this.fb.group({
            copiedFrom: [null],
            importDgqTemplates: [false],
            preset: [null]
        });

        this.form.valueChanges
            .pipe(takeUntil(this.unsubscribe$))
            .subscribe((changes) => {
                const parsedChanges = Object.entries(changes).reduce((result, [key, val]: [string, Record<string, any>]) => {
                    if (key === 'importDgqTemplates' || key === 'copiedFrom' || key === 'preset') {
                        result[key] = val;
                    } else if (key === 'exportOptions') {
                        const exportOptions = this.extractStringDigitsFromBooleans(val as Record<string, unknown>, [
                            'deltaExport',
                            'includeColumnNames',
                            'showEmptyTags',
                            'showEmptyParentTags',
                            'quotedFields',
                            'deduplicatePreserveNulls',
                            'useCdata',
                            'xmlWriteDocumentTag'
                        ]);

                        result = {
                            ...result,
                            ...val,
                            ...exportOptions
                        } as Record<string, any>;
                    } else {
                        result = {
                            ...result,
                            ...val
                        } as Record<string, any>;
                    }

                    return result;
                }, {} as Record<string, any>);

                this.exportChange.emit(parsedChanges)
            });
    }

    ngOnDestroy(): void {
        this.unsubscribe$.next();
        this.unsubscribe$.complete();
    }

    onSubmit(_e: SubmitEvent): void {
        this.addExport.emit();
    }

    accountHasFeature = (feature, value) => this.appStateService.getAccount().hasFeature(feature, value);

    addCredentialsForm(formGroup: UntypedFormGroup): void {
        this.addFormGroup('exportCredentials', formGroup);
    }

    addExportIfForm(formGroup: UntypedFormGroup): void {
        this.addFormGroup('exportIf', formGroup);
    }

    addExportOptionsForm(formGroup: UntypedFormGroup): void {
        this.addFormGroup('exportOptions', formGroup);
    }

    addExportRetriesForm(formGroup: UntypedFormGroup): void {
        this.addFormGroup('exportRetries', formGroup);
    }

    addExportTagsForm(formGroup: UntypedFormGroup): void {
        this.addFormGroup('exportTags', formGroup);
    }

    addFormGroup(controlName: string, formGroup: UntypedFormGroup): void {
        this.form.addControl(controlName, formGroup);
    }

    booleanToStringDigit(bool: boolean): '1' | '0' {
        return bool ? '1' : '0';
    }

    extractStringDigitsFromBooleans(obj: Record<string, unknown>, keys: string[]): Record<string, '1' | '0'> {
        return keys.reduce((result, key) => {
            const val = obj[key];

            if (typeof val === 'boolean') {
                result[key] = this.booleanToStringDigit(val);
            }

            return result;
        }, {});
    }

    changePreset(preset: PresetOption): void {
        if (!this.showAdvancedOptions) {
            this.toggleAdvancedOptions();
        }
        if (preset.filePath === 'template_string') {
            this.openImportTemplateStringModal();
            return;
        }

        this.jsonService.getData(preset.filePath)
            .pipe(takeUntil(this.unsubscribe$))
            .subscribe((presetData: any & { hint_text: ExportColumnType }) => {
                this.exportFields = [];

                if (preset.name === 'All') {
                    // field_name is equivalent to displayName
                    // field_0 is equivalent to field_ + field_offset => name
                    this.exportFields = this.aggregatedDbFields.validFields.map((fieldName) => {
                        return {
                            export_field_name: fieldName,
                            field_name: fieldName
                        };
                    });
                } else {
                    presetData.fields.forEach((field) => {
                        const isAdvancedTemplate = typeof field === 'object' && field !== null;

                        let required: ExportColumnType = '';
                        if (isAdvancedTemplate && field.hasOwnProperty('hint_text')) {
                            required = field.hint_text;
                        }

                        let exportFieldName = '';
                        if (isAdvancedTemplate && field.hasOwnProperty('export_name')) {
                            exportFieldName = field.export_name;
                        } else {
                            exportFieldName = field;
                        }

                        let fieldName = '';
                        this.aggregatedDbFields.validFields.forEach((dbFieldName) => {
                            let tempPresetName = exportFieldName;

                            // if we have a variable name - that's is a unique definition for the db field that we want to match on instead of the export name
                            if (isAdvancedTemplate && field.hasOwnProperty('variable_name')) {
                                tempPresetName = field.variable_name;
                            }
                            if (tempPresetName.indexOf('/') >= 0) {
                                tempPresetName = tempPresetName.split('/').pop();
                            }

                            if (dbFieldName.toLowerCase() === tempPresetName.toLowerCase()) {
                                fieldName = dbFieldName;
                            }

                            // The delta_status special rule should apply when part of a template as well
                            if(tempPresetName == '{{feedonomics::delta_status}}'){
                                fieldName = tempPresetName;
                            }
                        });

                        this.exportFields.push({
                            field_name: fieldName,
                            export_field_name: exportFieldName,
                            required
                        });
                    });

                    // check for existing export_template tag
                    let tag;
                    if (this.form.controls.exportTags) {
                        tag = this.form.controls.exportTags.value.tags.find((tag) => {
                            return tag.tag == 'export_template';
                        });
                    }

                    let exportTemplateName = presetData.name.toLowerCase().split(' ').join('_');

                    if (!tag) {
                        // add export tag for this preset
                        this.form.controls.exportTags.patchValue({
                            tags: [
                                {
                                    tag: 'export_template',
                                    value: exportTemplateName,
                                },
                                ...this.form.controls.exportTags.value.tags
                            ]
                        })
                    } else {
                        // replace existing export_template value
                        this.form.controls.exportTags.patchValue({
                            tags: this.form.controls.exportTags.value.tags.map((currentTag) => {
                                if (currentTag === tag) {
                                    return {
                                        tag: tag.tag,
                                        value: exportTemplateName
                                    };
                                }

                                return currentTag;
                            })
                        });
                    }
                }

                if (presetData.options) {
                    this.exportChange.emit(presetData.options);
                }
                this.exportFieldsChange.emit(this.exportFields);
            });
    }

    onExportFieldsChange(): void {
        this.form.markAsTouched();
        this.exportFieldsChange.emit(this.exportFields);
    }

    openImportTemplateStringModal(): void {
        this.modalService.open(ImportTemplateStringModalComponent, {
            size: 'lg',
            resolve: {
                exportId: this.exportItem?.id,
                exportFields: this.exportFields,
                dbFields: this.dbFields
            }
        })
            .closed
            .pipe(takeUntil(this.unsubscribe$))
            .subscribe((exportFields) => {
                this.exportFields = exportFields;
                this.exportFieldsChange.emit(this.exportFields);
            });
    }

    toggleAdvancedOptions(): void {
        this.showAdvancedOptions = !this.showAdvancedOptions;
    }

    openExportFieldsOffcanvas(): void {
        this.offcanvasService.open(ExportFieldsOffcanvasComponent, {
            position: 'end',
            resolve: {
                dbFields: this.dbFields,
                exportFields: this.exportFields,
                missingFields: [],
                exportSelector: this.exportItem.export_selector ?? 'true'
            } satisfies Partial<ExportFieldsOffcanvasResolver>
        }).closed.pipe(
            tap((newFields: ExportField[]) => {
                this.fdxUI.showToastSuccess(`${newFields.length} export fields added`);

                this.exportFields = this.exportFields.concat(newFields);
                this.onExportFieldsChange();
            }),
            takeUntil(this.unsubscribe$)
        ).subscribe();
    }
}
