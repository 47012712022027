import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { FTPLiabilityResponse } from '@app/core/models/interfaces/ftp-liability.interface';
import { catchError, map, Observable, of } from 'rxjs';

@Injectable({
    providedIn: 'root'
})
export class FtpLiabilityAcceptanceService {

    constructor(private http: HttpClient) { }

    hasAcceptedFTPLiability(dbId: string): Observable<boolean> {
        return this.http.get<FTPLiabilityResponse>(`/api.php/dbs/${dbId}/ftp_liability_acceptance`).pipe(
            map((response) => response.data.length > 0),
            catchError((_error: unknown) => of(false))
        )
    }

    acceptFTPLiability(dbId: string): Observable<{success: string}> {
        return this.http.post<{success: string}>(`/api.php/dbs/${dbId}/ftp_liability_acceptance`, null)
    }
}
