/* eslint-disable @typescript-eslint/no-explicit-any */
import { Clipboard } from '@angular/cdk/clipboard';
import { Inject, Injectable } from '@angular/core';
import { TimeUnitEnum } from '@app/core/models/enums/time-unit.enum';
import { WINDOW } from '@app/core/providers/window.provider';
import * as camelcaseKeys from 'camelcase-keys';
import { snakeCase } from 'change-case';
import { Observable } from 'rxjs';
import * as snakecaseKeys from 'snakecase-keys';

@Injectable({
    providedIn: 'root'
})
export class FdxUtilsService {

    /**
     * See if the user has reduced animations turned on. This can be the Windows setting
     * for "Show animations in Windows" or the "Reduce Motion" setting on Mac.
     * NOTE: For some reason, this doesn't work as an exported core function.
     */
    get hasReducedMotion(): boolean {
        return window.matchMedia('(prefers-reduced-motion: reduce)').matches;
    }

    constructor(
        @Inject(WINDOW) private readonly window: Window,
        private readonly clipboard: Clipboard
    ) { }

    isEmpty(value: unknown): boolean {
        return typeof value === 'undefined' || value === null || value === '' || (Array.isArray(value) && value.length === 0);
    }

    isObject(value: unknown): boolean {
        return typeof (value) === 'object' && value !== null && !Array.isArray(value);
    }

    copyTextToClipboard(text: string): Observable<void> {
        return new Observable((observer) => {
            const copied = this.clipboard.copy(text);
            if (copied) {
                observer.complete();
            } else {
                observer.error();
            }
        });
    }

    isValidEmail(email: string): boolean {
        const regExp = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        return regExp.test(String(email).toLowerCase());
    }

    isField(value: string): boolean {
        return value?.startsWith('[') && value.endsWith(']');
    }

    isUrl(url: string): boolean {
        if (typeof url === 'string') {
            const matches = url.match(/^(http[s]?:\/\/){0,1}(www\.){0,1}[a-zA-Z0-9.-]+\.[a-zA-Z]{2,5}[.]{0,1}/g);

            return matches !== null;
        }

        return false;
    }

    stringToNumber(input: string): number {
        if (!input) return NaN;
        if (input.trim().length === 0) {
            return NaN;
        }
        return Number(input);
    }

    camelToSnakeCase(str: string): string {
        return snakeCase(str);
    }

    camelToSnakeCaseObject(obj: Record<string, any>): Record<string, any> {
        return snakecaseKeys(obj);
    }

    snakeToCamelCaseObject(obj: Record<string, any>): Record<string, any> {
        return camelcaseKeys(obj);
    }

    clone(data: any): any {
        return JSON.parse(JSON.stringify(data));
    }

    normalizeURL(url: string): string {
        // for local testing
        if (url.indexOf('localhost/') >= 0) {
            const pos = url.indexOf('localhost/');
            url = this.window.location.protocol + '//' + this.window.location.host + '/' + url.slice(pos + 10);
        }
        if (!url.startsWith('http')) {
            url = '//' + url;
        }
        return url;
    }

    downloadURL(href: string, fileName: string = null): void {
        // H/T: https://ourcodeworld.com/articles/read/189/how-to-create-a-file-and-generate-a-download-with-javascript-in-the-browser-without-a-server
        const element = document.createElement('a');
        element.setAttribute('href', href);
        if (fileName) {
            element.setAttribute('download', fileName);
        }
        element.style.display = 'none';
        document.body.appendChild(element);
        element.click();
        document.body.removeChild(element);
    }

    /**
     * This method takes in two separate values (a numeric value and its unit) and converts it to minutes.
     * This is useful when you need a common format for two time values that are not associated with a date.
     * @param value
     * @param unit - TimeUnitEnum that represents the units
     */
    convertToMinutes(value: number, unit: TimeUnitEnum): number {
        const minutesPerHour = 60;
        const minutesPerDay = 24 * minutesPerHour;
        const minutesPerWeek = 7 * minutesPerDay;
        const minutesPerMonth = 4 * minutesPerWeek;

        switch (unit) {
            case TimeUnitEnum.MINUTES:
                return value;
            case TimeUnitEnum.HOURS:
                return minutesPerHour * value;
            case TimeUnitEnum.DAYS:
                return minutesPerDay * value;
            case TimeUnitEnum.WEEKS:
                return minutesPerWeek * value;
            case TimeUnitEnum.MONTHS:
                return minutesPerMonth * value;
            default:
                throw new Error('[FdxUtils:convertToMinutes] Unhandled value for unit param');
        }
    }

    getFileExtensionFromDelimiter(delimiter: string): string {
        switch (delimiter) {
            case 'comma':
                return 'csv';
            case 'tab':
                return 'tsv';
            default:
                return 'txt';
        }
    }

    reverseComparison<T>(compareFn: (a: T, b: T) => number): (a: T, b: T) => number {
        return (a: T, b: T) => {
            const comparison = compareFn(a, b);
            if (comparison === 0) {
                return 0;
            }
            return -1 * comparison;
        };
    }

    areArraysEqualOrderIndifferent(a1: Array<unknown>, a2: Array<unknown>): boolean {
        const sameLength = a1.length === a2.length;
        return sameLength && a1.every((a) => a2.some((b) => a === b));
    }

    caseInsensitiveComparator(valueA: string, valueB: string): number {
        return valueA.toLowerCase().localeCompare(valueB.toLowerCase());
    }
}
