import { AccountModel } from '@ajs/models/account';
import AppStateService from '@ajs/services/AppStateService';
import { Injectable, OnDestroy, Signal, signal, WritableSignal } from '@angular/core';
import { AccountFeatureFlag, BigCommerceSelfServiceMode } from '@app/core/models/enums/account-feature-flag.enum';
import { combineLatest, filter, Subject, takeUntil, tap } from 'rxjs';

@Injectable({
    providedIn: 'root'
})
export class BigCommerceSelfServiceState implements OnDestroy {
    protected readonly unsubscribe$: Subject<void> = new Subject<void>();

    private mode: BigCommerceSelfServiceMode;
    private _showRestrictedUI: WritableSignal<boolean> = signal(false);
    public showRestrictedUI: Signal<boolean> = this._showRestrictedUI.asReadonly();

    private get account(): AccountModel | null {
        return this.appStateService.getAccount();
    }

    public get isFree(): boolean {
        return this.mode === BigCommerceSelfServiceMode.FREE;
    }

    public get isPaid(): boolean {
        return this.mode === BigCommerceSelfServiceMode.PAID;
    }

    constructor(
        private readonly appStateService: AppStateService
    ) {
        combineLatest([appStateService.userLoaded$, appStateService.accountLoaded$])
            .pipe(
                filter(([user, account]) => user !== null && account !== null),
                tap(() => {
                    this.setMode();
                    const value = this.getShowRestrictedUI();
                    this._showRestrictedUI.set(value);
                }),
                takeUntil(this.unsubscribe$)
            ).subscribe();
    }

    ngOnDestroy(): void {
        this.unsubscribe$.next();
        this.unsubscribe$.complete();
    }

    private setMode(): void {
        const isFree = this.account?.hasFeature(AccountFeatureFlag.BIGCOMMERCE_SELFSERVICE_UX, BigCommerceSelfServiceMode.FREE);
        if (isFree) {
            this.mode = BigCommerceSelfServiceMode.FREE;
            return;
        }

        const isPaid = this.account?.hasFeature(AccountFeatureFlag.BIGCOMMERCE_SELFSERVICE_UX, BigCommerceSelfServiceMode.PAID);
        if (isPaid) {
            this.mode = BigCommerceSelfServiceMode.PAID;
            return;
        }

        this.mode = BigCommerceSelfServiceMode.NONE;
    }

    /**
     * This method determines if we should show the BigCommerce Self-Service UI mode. This is a limited set a features
     * to streamline the user experience for BigCommerce self-service users.
     *
     * This is turned on via an account level feature flag, and can be overwritten if the user is in demo mode, or the user's
     * privacy level is at least an Analyst.
     */
    private getShowRestrictedUI(): boolean {
        // If the account does not have the BC Self-Service mode feature flag enabled - do not show this corresponding UI
        if (this.mode === BigCommerceSelfServiceMode.NONE) {
            return false;
        }

        // If in demo mode, restrict features to just the BigCommerce self-service mode.
        const user = this.appStateService.getUser();
        if (user.isInDemoView()) {
            return true;
        }

        // If demo mode is not enabled, do not restrict analysts to BigCommerce Self-Service UI mode
        if (this.appStateService.isPrivacyLevelAtLeastAnalyst()) {
            return false;
        }

        return true;
    }
}
