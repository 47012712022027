import { CommonModule } from '@angular/common';
import { Component, EventEmitter, Input, Output } from '@angular/core';
import { AmazonCategory } from '@app/category-taxonomy/models/enums/amazon-category.enum';
import { CategoryPath } from '@app/category-taxonomy/services/responses/category-paths.response';

@Component({
    standalone: true,
    selector: 'fdx-category-paths',
    templateUrl: './category-paths.component.html',
    styleUrls: ['./category-paths.component.scss'],
    imports: [
        CommonModule
    ]
})
export class CategoryPathsComponent {

    @Input() categoryPaths: CategoryPath[];
    @Input() showExtraPaths: boolean = false;
    @Input() expandable: boolean = false;
    @Input() amazonCategoryType?: AmazonCategory = null;
    @Input() itemTypeText?: string = null;

    @Input() expanded: boolean = false;
    @Output() readonly expandedChange: EventEmitter<boolean> = new EventEmitter<boolean>();

    get showingProductType(): boolean {
        return this.amazonCategoryType === AmazonCategory.ProductType;
    }

    get pathTypeText(): string {
        switch (this.amazonCategoryType) {
            case AmazonCategory.ItemType:
                return this.categoryPaths.length > 0 ? this.categoryPaths[0].meta?.productType : null;
            case AmazonCategory.ProductType:
                return this.itemTypeText;
            default:
                return null;
        }
    }

    trackByIndex(index: number): number {
        return index;
    }

    toggleExpand(): void {
        this.expanded = !this.expanded;
        this.expandedChange.emit(this.expanded);
    }
}
