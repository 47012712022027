export enum UserFeatureFlag {
    CLASSIFIER_INFO = 'classifier_info',
    DATA_PAGE_FIELD_POPOVER = 'data_page_field_popover',
    ERROR_RESOLUTION_MODE = 'error_resolution_mode',
    EXPORT_WEBHOOK_FOR_POSTMAN = 'export_webhook_for_postman',
    GENAI = 'genai',
    GENAI_CATEGORIZATION = 'genai_categorization',
    GENAI_ATTRIBUTES = 'genai_attributes',
    IMPORT_SHOPIFY_THIRD_PARTY_AUTHENTICATION = 'import_shopify_third_party_authentication',
    PAUSE_IMPORTS_EXPORTS = 'pause_imports_exports',
    RUN_ATTRIBUTION = 'run_attribution',
    RUN_CLASSIFY = 'run_classify',
    TRANSFORMED_DATA_OVERRIDES = 'transformed_data_overrides'
}
