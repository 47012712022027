<ng-template #icon>
    <fa-icon *ngIf="showIcon" [icon]="iconExclamation" class="me-1"></fa-icon>
</ng-template>

<div class="validation-component" *ngIf="control.invalid && (control.dirty || control.touched || submitted)">
    <div *ngIf="customValidationText; else noCustomText">
        <ng-container *ngTemplateOutlet="icon"></ng-container>{{ customValidationText }}
    </div>
    <ng-template #noCustomText>
        <div *ngIf="control.errors?.['pattern']">
            <ng-container *ngTemplateOutlet="icon"></ng-container>{{ labelText }} {{ validationText.Pattern }} '{{ control.errors.pattern.requiredPattern }}'
        </div>
        <div *ngIf="control.errors?.['min']">
            <ng-container *ngTemplateOutlet="icon"></ng-container>{{ labelText }} {{ validationText.Min }} {{ control.errors.min.min }}
        </div>
        <div *ngIf="control.errors?.['max']">
            <ng-container *ngTemplateOutlet="icon"></ng-container>{{ labelText }} {{ validationText.Max }} {{ control.errors.max.max }}
        </div>
        <div *ngIf="control.errors?.['required']">
            <ng-container *ngTemplateOutlet="icon"></ng-container>{{ labelText }} {{ validationText.Required }}
        </div>
        <div *ngIf="control.errors?.['minlength']">
            <ng-container *ngTemplateOutlet="icon"></ng-container>{{ labelText }} {{ validationText.MinlengthStart }} {{ control.errors.minlength.requiredLength }} {{ validationText.LengthEnd }}
        </div>
        <div *ngIf="control.errors?.['maxlength']">
            <ng-container *ngTemplateOutlet="icon"></ng-container>{{ labelText }} {{ validationText.MaxlengthStart }} {{ control.errors.maxlength.requiredLength }} {{ validationText.LengthEnd }}
        </div>
        <div *ngIf="control.errors?.['email']">
            <ng-container *ngTemplateOutlet="icon"></ng-container>{{ labelText }} {{ validationText.Email }}
        </div>
        <div *ngIf="control.errors?.['emailDelimited']">
            <ng-container *ngTemplateOutlet="icon"></ng-container>{{ labelText }} {{ validationText.EmailDelimited }} {{ control.errors.emailDelimited.separator === ',' ? 'commas' : '"' + control.errors.emailDelimited.separator + '"s'}}
        </div>
        <div *ngIf="control.errors?.['requiredTrue']">
            <ng-container *ngTemplateOutlet="icon"></ng-container>{{ labelText }} {{ validationText.RequiredTrue }}
        </div>
        <div *ngIf="control.errors?.['invalidPasswordLength']">
            <ng-container *ngTemplateOutlet="icon"></ng-container>{{ labelText }} {{ validationText.MinlengthStart }} {{ control.errors.invalidPasswordLength.requiredLength }} {{ validationText.LengthEnd }}
        </div>
        <div *ngIf="control.errors?.['invalidPasswordChars']">
            <ng-container *ngTemplateOutlet="icon"></ng-container>{{ labelText }} {{ control.errors.invalidPasswordChars.required }}
        </div>
        <div *ngIf="control.errors?.['invalidBlobSize']">
            <ng-container *ngTemplateOutlet="icon"></ng-container>{{ control.errors.invalidBlobSize.message }}
        </div>
        <div *ngIf="control.errors?.['invalidJson']">
            <ng-container *ngTemplateOutlet="icon"></ng-container>{{ control.errors.invalidJson.message }}
        </div>
        <div *ngIf="control.errors?.['requiredCheckboxOrRadioToBeChecked']">
            <ng-container *ngTemplateOutlet="icon"></ng-container>{{ control.errors.requiredCheckboxOrRadioToBeChecked.message }}
        </div>
        <div *ngIf="control.errors?.['endBeforeStartDate']">
            <ng-container *ngTemplateOutlet="icon"></ng-container>{{ control.errors.endBeforeStartDate.message }}
        </div>
        <div *ngIf="control.errors?.['minMaxValidation']">
            <ng-container *ngTemplateOutlet="icon"></ng-container>{{ control.errors.minMaxValidation.message }}
        </div>
        <div *ngIf="control.errors?.['invalidFileType']">
            <ng-container *ngTemplateOutlet="icon"></ng-container>{{ control.errors.invalidFileType.message }}
        </div>
        <div *ngIf="control.errors?.['currentPasswordMatchesNew']">
            <ng-container *ngTemplateOutlet="icon"></ng-container>{{ control.errors.currentPasswordMatchesNew.message }}
        </div>
        <div *ngIf="control.errors?.['confirmPasswordNotMatchesNew']">
            <ng-container *ngTemplateOutlet="icon"></ng-container>{{ control.errors.confirmPasswordNotMatchesNew.message }}
        </div>
        <div *ngIf="control.errors?.['username']">
            <ng-container *ngTemplateOutlet="icon"></ng-container>{{ control.errors.username.message }}
        </div>
        <div *ngIf="control.errors?.['invalidEmails']">
            <ng-container *ngTemplateOutlet="icon"></ng-container>{{ control.errors.invalidEmails.length }} {{ control.errors.invalidEmails.length > 1 ? validationText.EmailList :  validationText.EmailListSingular }}
        </div>
    </ng-template>
</div>
