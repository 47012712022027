<div class="d-flex flex-column align-items-center px-2 py-3 gap-1 h-100">
    @for (navGroup of navGroups; track navGroup.id; let isLast = $last) {
        @if (navGroup.children) {
            <!-- Has sub navs and group nav is internal link -->
            <div
                ngbDropdown
                fscHoverDropdown
                [closeDelay]="300"
                placement="right-top right-bottom"
                [ngClass]="isLast ? 'mt-auto' : ''">
                <a
                    ngbDropdownToggle
                    [routerLink]="navGroup.link"
                    (click)="navStateService.onNavGroupClick(navGroup.id)"
                    [ngClass]="getNavGroupClass(navGroup.id)"
                    class="btn btn-link btn-ghost btn-nav p-0 d-flex justify-content-center align-items-center">
                    <fa-icon
                        [icon]="navGroup.icon"
                        [ngClass]="{'text-secondary': !isNavGroupSelected(navGroup.id), 'text-primary-500': isNavGroupSelected(navGroup.id) }"
                        class="no-margin"/>
                    <div
                        ngbDropdownMenu
                        class="ms-1 rounded-3">
                        <a
                            ngbDropdownItem
                            [routerLink]="navGroup.link"
                            class="d-flex position-relative"
                            [ngClass]="getDropdownClasses(navGroup.id, true)"
                            (click)="navStateService.onNavGroupClick(navGroup.id)">
                            <span>{{navGroup.label}}</span>
                        </a>
                        @for (subNav of navGroup.children; track subNav.id) {
                            @if (subNav.isExternalLink) {
                                <!-- External sub nav link-->
                                <a
                                    ngbDropdownItem
                                    [href]="subNav.link[0]"
                                    target="_blank"
                                    rel="noopener"
                                    referrerpolicy="no-referrer"
                                    class="unselected-item d-flex justify-content-between">
                                    <span>{{subNav.label}}</span>
                                    <fa-icon
                                        class="btn btn-link text-secondary p-0 no-margin"
                                        [icon]="faArrowUpRightFromSquare"/>
                                </a>
                            } @else {
                                <!-- Internal sub nav link-->
                                <a
                                    ngbDropdownItem
                                    [routerLink]="subNav.link"
                                    class="d-flex position-relative"
                                    [ngClass]="getDropdownClasses(subNav.id, false)"
                                    (click)="navStateService.onSubNavClick(subNav.id, navGroup.id)">
                                    <span>{{subNav.label}}</span>
                                </a>
                            }
                        }
                    </div>
                </a>
            </div>
        } @else if (!navGroup.isExternalLink) {
            <!-- Has no sub navs and is internal link -->
            <a
                [routerLink]="navGroup.link"
                [ngbPopover]="popover"
                triggers="mouseenter:mouseleave"
                [openDelay]="300"
                [closeDelay]="300"
                placement="right"
                [ngClass]="[getNavGroupClass(navGroup.id), isLast ? 'mt-auto' : '']"
                class="btn btn-link btn-ghost btn-nav p-0 d-flex justify-content-center align-items-center"
                (click)="navStateService.onNavGroupClick(navGroup.id)">
                <fa-icon
                    [icon]="navGroup.icon"
                    [ngClass]="{'text-secondary': !navStateService.isNavGroupSelected(navGroup.id), 'text-primary-500': navStateService.isNavGroupSelected(navGroup.id) }"
                    class="no-margin"/>
            </a>
        } @else {
            <!-- Has no sub navs and is external link -->
            <a
                    [href]="navGroup.link[0]"
                    target="_blank"
                    rel="noopener"
                    referrerpolicy="no-referrer"
                    [ngbPopover]="popover"
                    triggers="mouseenter:mouseleave"
                    [openDelay]="300"
                    [closeDelay]="300"
                    placement="right"
                    [ngClass]="[ isLast ? 'mt-auto' : '']"
                    class="btn btn-link btn-ghost btn-nav p-0 d-flex justify-content-center align-items-center unselected-item">
                <fa-icon
                        [icon]="navGroup.icon"
                        [ngClass]="{'text-secondary': !navStateService.isNavGroupSelected(navGroup.id), 'text-primary-500': navStateService.isNavGroupSelected(navGroup.id) }"
                        class="no-margin"/>
            </a>
        }
        <ng-template #popover>
            <div class="d-flex nav-popover align-items-center">
                {{navGroup.label}}
                @if (navGroup.isExternalLink) {
                    <fa-icon
                            class="btn btn-link text-secondary p-0 no-margin ms-2"
                            [icon]="faArrowUpRightFromSquare"/>
                }
            </div>
        </ng-template>
    }
</div>
