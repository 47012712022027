import { NgModule } from '@angular/core';
import { DurationPipe } from '@app/modules/pipes/pipes/duration/duration.pipe';
import { EscapeHtmlPipe } from '@app/modules/pipes/pipes/escape-html/escape-html.pipe';
import { FileSizePipe } from '@app/modules/pipes/pipes/file-size/file-size.pipe';
import { FormatSecondsPipe } from '@app/modules/pipes/pipes/format-seconds/format-seconds.pipe';
import { MomentPipe } from '@app/modules/pipes/pipes/moment/moment.pipe';
import { NonprintablePipe } from '@app/modules/pipes/pipes/nonprintable/nonprintable.pipe';
import { OrderByPipe } from '@app/modules/pipes/pipes/order-by/order-by.pipe';
import { OrdinalPipe } from '@app/modules/pipes/pipes/ordial/ordinal.pipe';
import { PrettyHtmlPipe } from '@app/modules/pipes/pipes/pretty-html/pretty-html.pipe';
import { SearchPipe } from '@app/modules/pipes/pipes/search/search.pipe';
import { SentenceCasePipe } from '@app/modules/pipes/pipes/sentence-case/sentence-case.pipe';
import { ShortenNumberPipe } from '@app/modules/pipes/pipes/shorten-number/shorten-number.pipe';
import { TruncatePipe } from '@app/modules/pipes/pipes/truncate/truncate.pipe';

@NgModule({
    declarations: [
        DurationPipe,
        EscapeHtmlPipe,
        FileSizePipe,
        FormatSecondsPipe,
        MomentPipe,
        NonprintablePipe,
        OrderByPipe,
        OrdinalPipe,
        PrettyHtmlPipe,
        SearchPipe,
        SentenceCasePipe,
        ShortenNumberPipe,
        TruncatePipe
    ],
    providers: [
        DurationPipe,
        EscapeHtmlPipe,
        FileSizePipe,
        FormatSecondsPipe,
        MomentPipe,
        NonprintablePipe,
        OrderByPipe,
        OrdinalPipe,
        PrettyHtmlPipe,
        SearchPipe,
        ShortenNumberPipe,
        TruncatePipe
    ],
    exports: [
        DurationPipe,
        EscapeHtmlPipe,
        FileSizePipe,
        FormatSecondsPipe,
        MomentPipe,
        NonprintablePipe,
        OrderByPipe,
        OrdinalPipe,
        PrettyHtmlPipe,
        SearchPipe,
        SentenceCasePipe,
        ShortenNumberPipe,
        TruncatePipe
    ]
})
export class PipesModule { }
