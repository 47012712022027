<modal-hoc
    [modalTheme]="modalTheme"
    [modalTitle]="title"
    [submitButton]="false"
    [cancelButton]="false"
    (modalCancel)="onModalCancel($event)">
    <div class="d-flex flex-column gap-3">
        <ng-container *ngFor="let category of categories | keyvalue; trackBy: trackByKey">
            <fdx-category-paths
                [categoryPaths]="category.value"
                [itemTypeText]="filterByProductType ? category.key : null"
                [amazonCategoryType]="amazonCategoryType"/>
        </ng-container>
    </div>
</modal-hoc>
