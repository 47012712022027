<main class="container py-4" *ngIf="loading; else errorOverview">
    <fdx-spinner></fdx-spinner>
</main>

<ng-template #errorOverview>
    <main class="container py-4">
        <ng-container *ngIf="channels.length === 0; else errorOverviewData">
            <fsc-empty-state
                class="fdx-center-empty-state"
                headerText="No channels with error overview data"
                bodyText="The exports for this database don't have any channels with error resolution."
                buttonText="Add an export for a channel"
                (buttonClick)="goToAddExport()">
            </fsc-empty-state>
        </ng-container>

        <ng-template #errorOverviewData>
            <div class="d-flex align-items-center gap-3 mb-3" [formGroup]="form">
                <fdx-page-title [classes]="{'mb-0': true}">Error overview</fdx-page-title>
                <ng-select [clearable]="false" formControlName="channel" class="channel-selection" [loading]="loading"
                    (change)="onChannelChanged($event)">
                    <ng-option *ngFor="let channel of channels; trackBy: trackByIndex" [value]="channel">{{channel}}</ng-option>
                </ng-select>
                <button type="button" class="btn btn-outline-secondary ms-auto" (click)="onDownload()" [disabled]="initialized || allDataList.length === 0">
                    <fa-icon [icon]="iconDownload"></fa-icon>Download
                </button>
            </div>

            <div class="card mb-3">
                <div class="card-body">
                    <fsc-search-bar inputPlaceholder="Search error message and error code" class="w-100" (queryChange)="onSearch($event)" #searchbar>
                    </fsc-search-bar>
                </div>
            </div>

            <div class="card error-data-table">
                <div class="card-header fdx-list-table-header align-items-center gap-2">
                    <div class="error-count-col">
                        Errors / warnings
                    </div>
                    <div class="error-message-col fdx-flex-1">
                        Error message
                    </div>
                    <div class="error-code-col">
                        Error code
                    </div>
                </div>
                <div class="card-body p-0 table-body" #tablerow>
                    <div class="my-3" *ngIf="initialized">
                        <fdx-spinner></fdx-spinner>
                    </div>

                    <ng-container *ngIf="!initialized">
                        <div *ngFor="let item of filteredLists; trackBy: trackByIndex" class="table-row">
                            <div class="fdx-list-table-item align-items-start gap-2">
                                <div class="error-count-col">
                                    <fdx-icon-badge-button
                                        [theme]="getTheme(item)"
                                        [text]="item.count"
                                        [desaturateWhenZero]="true"
                                        (buttonClick)="goToErrorResolution(item.query)">
                                    </fdx-icon-badge-button>
                                </div>
                                <div class="error-message-col fdx-flex-1" [innerHTML]="item.error_message" >
                                </div>
                                <div class="error-code-col">
                                    {{item.error_code}}
                                </div>
                            </div>
                        </div>
                        <div class="text-center my-3" *ngIf="filteredLists.length === 0">
                            No error data with selected channel
                        </div>
                    </ng-container>
                </div>

                <div class="card-body p-0 pagination-row">
                    <ng-container *ngIf="!initialized && filteredLists.length !== 0">
                        <div class="px-3 py-3 d-flex justify-content-between align-items-center">
                            <fdx-pagination-bar class="flex-grow-1" [hidePerPageSelector]="false" [pageSize]="pageSize"
                                [recordsCount]="allDataList.length" (pageChanged)="onPageChange($event)">
                            </fdx-pagination-bar>
                        </div>
                    </ng-container>
                </div>
            </div>
        </ng-template>
    </main>
</ng-template>
