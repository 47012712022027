import { Component } from '@angular/core';
import { BootstrapThemeProp } from '@app/core/services/bootstrap-theme.service';
import { AdditionalModalDismissReasons } from '@app/modules/modals/enums/additional-modal-dismiss-reasons';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'fdx-ftp-liability-modal',
  templateUrl: './ftp-liability-modal.component.html',
  styleUrls: ['./ftp-liability-modal.component.scss']
})
export class FTPLiabilityModalComponent {
    public modalTheme: BootstrapThemeProp = 'warning';
    public title: string = 'Legal disclaimer';
    public confirmText: string = 'Yes';
    public cancelText: string = 'No';

    constructor(private readonly modal: NgbActiveModal) { }

    public onModalSubmit(_event: MouseEvent): void {
        this.modal.close();
    }

    public onModalCancel(_event: MouseEvent): void {
        this.modal.dismiss(AdditionalModalDismissReasons.CANCEL_CLICK);
    }
}
