import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { AlertModule } from '@app/modules/alerts/alert.module';
import { AppEbayMenuComponent } from '@app/modules/nav/components/app-ebay-menu/app-ebay-menu.component';
import { AppMainMenuComponent } from '@app/modules/nav/components/app-main-menu/app-main-menu.component';
import { AppVerticalMenuCollapsedComponent } from '@app/modules/nav/components/app-vertical-menu-collapsed/app-vertical-menu-collapsed.component';
import { AppVerticalMenuExpandedComponent } from '@app/modules/nav/components/app-vertical-menu-expanded/app-vertical-menu-expanded.component';
import { AppVerticalMenuComponent } from '@app/modules/nav/components/app-vertical-menu/app-vertical-menu.component';
import { BreadcrumbsComponent } from '@app/modules/nav/components/breadcrumbs/breadcrumbs.component';
import { PipesModule } from '@app/modules/pipes/pipes.module';
import { UserInitialsComponent } from '@app/standalone-components/user-initials/user-initials.component';
import { DropdownsModule } from '@feedonomics/frontend-components';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { NgbAccordionModule, NgbDropdownModule, NgbNavModule, NgbPopoverModule, NgbTooltipModule } from '@ng-bootstrap/ng-bootstrap';

@NgModule({
    declarations: [
        AppEbayMenuComponent,
        AppMainMenuComponent,
        AppVerticalMenuCollapsedComponent,
        AppVerticalMenuComponent,
        AppVerticalMenuExpandedComponent,
        BreadcrumbsComponent
    ],
    exports: [
        AppEbayMenuComponent,
        AppMainMenuComponent,
        AppVerticalMenuComponent,
        BreadcrumbsComponent
    ],
    imports: [
        AlertModule,
        CommonModule,
        DropdownsModule,
        FontAwesomeModule,
        NgbAccordionModule,
        NgbDropdownModule,
        NgbNavModule,
        NgbPopoverModule,
        NgbTooltipModule,
        PipesModule,
        RouterModule,
        UserInitialsComponent
    ]
})
export class NavModule { }
