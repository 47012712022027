import { Injectable } from '@angular/core';

@Injectable({
    providedIn: 'root'
})
export class NavStateService {
    private activeGroupNavId: string;
    private activeSubNavId: string;

    isNavGroupSelected(id: string): boolean {
        return this.activeGroupNavId === id;
    }

    isSubNavSelected(id: string): boolean {
        return this.activeSubNavId === id;
    }

    isSubNavUnset(): boolean {
        return this.activeSubNavId === undefined;
    }

    /**
     * The nav group is located at the root of the navigation bar.
     * When expanded, it is the top level accordion item, with sub nav items
     * When collapsed, it is the icon in the bar, as well as the first item in the dropdown menu list
     *
     * Having sub nav items is optional.
     * @param id
     */
    onNavGroupClick(id: string): void {
        this.activeGroupNavId = id;
        this.activeSubNavId = undefined
    }

    /**
     * The sub nav items are located under a group.
     *
     * When expanded, they are the navigation items underneath a collapsible group accordion header
     * When collapsed, they are the items in the dropdown menu list (after the first item which is the group nav)
     * @param id
     * @param parentId
     */
    onSubNavClick(id: string, parentId: string): void {
        this.activeGroupNavId = parentId
        this.activeSubNavId = id;
    }
}
