export enum AccountPermissions {
    primary = 'primary',
    analyst = 'analyst',
    admin = 'admin'
}

export enum InvitedPermissionsStatus {
    ACCEPTED = 'accepted',
    PENDING = 'pending',
    NO_INVITE = 'no invite'
}

export interface InvitedPermission {
    user_id: string;
    permissions: AccountPermissions;
    status: InvitedPermissionsStatus;
}

export interface AccountDataModel {
    id: number;
    account_name: string;
    default_technical_support_email: string;
    features: Record<string, string>;
    logo: string;
    permissions: AccountPermissions;
    invited_permissions: InvitedPermission;
}
