import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest, HttpXsrfTokenExtractor } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

/**
 * This `HttpInterceptor` grabs the value from the `XSRF-TOKEN`
 * cookie and sets it as the `X-XSRF-TOKEN` HTTP header to all
 * API requests. This is for Cross-Site Request Forgery (CSRF)
 * protection.
 * 
 * Based on the built-in Angular `HttpXsrfInterceptor`:
 * https://github.com/angular/angular/blob/main/packages/common/http/src/xsrf.ts
 */
@Injectable()
export class HttpXsrfWithGetInterceptor implements HttpInterceptor {
    constructor(
        private readonly extractor: HttpXsrfTokenExtractor
    ) { }

    public static readonly XSRF_HEADER_NAME: string = 'X-XSRF-TOKEN';

    public intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
        const normalizedUrl = request.url.toLowerCase();

        // Skip both non-mutating requests and absolute URLs.
        // Non-mutating requests don't require a token, and absolute URLs require special handling
        // anyway as the cookie set
        // on our origin is not the same as the token expected by another origin.
        // For Feedonomics, do handle GET requests as we may have GET requests
        // that are mutating data.
        if (
            request.method === 'HEAD' ||
            normalizedUrl.startsWith('http://') ||
            normalizedUrl.startsWith('https://')
        ) {
            return next.handle(request);
        }

        const token = this.extractor.getToken();
        const headerName = HttpXsrfWithGetInterceptor.XSRF_HEADER_NAME;

        // Be careful not to overwrite an existing header of the same name.
        if (token !== null && !request.headers.has(headerName)) {
            request = request.clone(
                {
                    headers: request.headers.set(
                        headerName,
                        token
                    )
                }
            );
        }

        return next.handle(request);
    }
}
