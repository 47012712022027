<fdx-style-wrapper>
    <block-ui>
        <div
            class='app'
            [ngClass]="{ 'view-height': viewHeight, 'vertical-nav': bigcommerceSelfService.showRestrictedUI() }">

                @if (isAccountsUrl) {
                    <fdx-app-main-menu/>
                } @else if (appStateService.account) {
                    @if (!showEbayUI && !bigcommerceSelfService.showRestrictedUI()) {
                        <fdx-app-main-menu/>
                    } @else if(showEbayUI) {
                        <fdx-app-ebay-menu/>
                    } @else if (bigcommerceSelfService.showRestrictedUI()) {
                        <fdx-app-vertical-menu/>
                    }
                }

            <router-outlet></router-outlet>
        </div>
        <div id="bs5-container"></div>
    </block-ui>
</fdx-style-wrapper>
