import { downgradeInjectable } from '@angular/upgrade/static';
import { MODULE_NAME } from '../config/constants';

// DATA SERVICES
import { ImportFtpTriggerService } from '@app/imports/services/import-ftp-trigger.service';
import AccountsDataService from './AccountsDataService.js';
import AttributionDataService from './AttributionDataService.js';
import AuthorizationDataService from './AuthorizationDataService';
import ClassificationDataService from './ClassificationDataService.js';
import DatabaseGroupsDataService from './DatabaseGroupsDataService.js';
import DatabasesDataService from './DatabasesDataService.js';
import EventAlertsDataService from './EventAlertsDataService.js';
import ExportsDataService from './ExportsDataService';
import FileMapService from './FileMapService';
import FtpTriggersDataService from './FtpTriggersDataService';
import ImportsDataService from './ImportsDataService.js';
import ReleaseNotesDataService from './ReleaseNotesDataService';
import StoresDataService from './StoresDataService.ts';
import TransformersDataService from './TransformersDataService.js';
import UsersDataService from './UsersDataService.ts';
import VaultDataService from './VaultDataService.js';

// OTHER SERVICES
import { LocalStorageService } from '@app/core/services/local-storage.service';
import { FeedSupportStateService as FeedSupportStateServiceNew } from '@app/feed-support/services/feed-support-state.service';
import { ModalService } from '@app/modules/modals/services/modal.service';
import { OffcanvasService } from '@app/modules/offcanvas/services/offcanvas.service';
import AnalyticsService from './AnalyticsService.ts';
import AppStateService from './AppStateService.ts';
import CSVService from './CSVService.js';
import DataDownloadService from './DataDownloadService';
import FeedSupportStateService from './FeedSupportStateService.ts';
import FeedWizardService from './FeedWizardService.js';
import ImpersonateUserService from './ImpersonateUserService';
import ImportsValidationService from './ImportsValidationService.js';
import LogService from './LogService';
import NavigationService from './NavigationService';
import ReleaseNotesService from './ReleaseNotesService';
import ResolversService from './ResolversService';
import ShopifyService from './ShopifyService';
import TitleService from './TitleService';
import UserAccessService from './UserAccessService';
import { WebhooksTypesDataService } from './WebhooksTypesDataService';
import fdxUI from './fdxUI';
import fdxUtils from './fdxUtils';


angular.module(MODULE_NAME)
  // DATA SERVICES
  .service('AccountsDataService', AccountsDataService)
  .service('AttributionDataService', AttributionDataService)
  .service('AuthorizationDataService', AuthorizationDataService)
  .service('ClassificationDataService', ClassificationDataService)
  .service('DatabaseGroupsDataService', DatabaseGroupsDataService)
  .service('DatabasesDataService', DatabasesDataService)
  .service('EventAlertsDataService', EventAlertsDataService)
  .service('ExportsDataService', ExportsDataService)    // Currently only being used in ebay_selection-component.js
  .service('FtpTriggersDataService', FtpTriggersDataService)
  .service('ImportFtpTriggerService', downgradeInjectable(ImportFtpTriggerService))
  .service('ImportsDataService', ImportsDataService)
  .service('ReleaseNotesDataService', ReleaseNotesDataService)
  .service('StoresDataService', StoresDataService)
  .service('TransformersDataService', TransformersDataService)
  .service('UsersDataService', UsersDataService)
  .service('VaultDataService', VaultDataService)
  .service('WebhooksTypesDataService', WebhooksTypesDataService)
  // OTHER SERVICES
  .service('AnalyticsService', AnalyticsService)
  .service('AppStateService', AppStateService)
  .service('CSVService', CSVService)
  .service('DataDownloadService', DataDownloadService)
  .service('FeedSupportStateService', FeedSupportStateService)
  .service('FeedSupportStateServiceNew', downgradeInjectable(FeedSupportStateServiceNew))
  .service('FeedWizardService', FeedWizardService)
  .service('FileMapService', FileMapService)
  .service('ImpersonateUserService', ImpersonateUserService)
  .service('ImportsValidationService', ImportsValidationService)
  .service('LocalStorageService', downgradeInjectable(LocalStorageService))
  .service('LogService', LogService)
  .service('NgbModalService', downgradeInjectable(ModalService))
  .service('NavigationService', NavigationService)
  .service('OffcanvasService', downgradeInjectable(OffcanvasService))
  .service('ReleaseNotesService', ReleaseNotesService)
  .service('ResolversService', ResolversService)
  .service('ShopifyService', ShopifyService)
  .service('TitleService', TitleService)
  .service('UserAccessService', UserAccessService)
  .service('fdxUI', fdxUI)
  .service('fdxUtils', fdxUtils);
