<!-- eslint-disable @angular-eslint/template/no-inline-styles -->
<!-- eslint @angular-eslint/template/use-track-by-function:warn -->
<div class="d-flex" #container>
    <nav class="h-100 flex-column align-items-stretch pe-5 border-end">
        <nav class="nav nav-pills flex-column">
            @for (tab of tabs; track tab.title) {
                <button
                    type="button"
                    class="nav-link text-start"
                    [ngClass]="tabClass(tab.title)"
                    (click)="scrollTo(tab.title)">
                    {{ tab.title }}
                </button>
                @for (group of tab.data; track group.title) {
                    <nav class="nav nav-pills flex-column">
                        <button
                            type="button"
                            class="nav-link ms-3 my-1 text-start"
                            [ngClass]="groupClass(tab.title + '_' + group.title)"
                            (click)="scrollTo(tab.title + '_' + group.title)">
                            {{ group.title }}
                        </button>
                    </nav>
                }
            }
        </nav>
    </nav>

    <div class="flex-grow-1 ms-5">
        <div class="d-flex flex-column pe-2 overflow-auto" [ngStyle]="{'height': height}" scrollSpy [spiedTags]="['DIV']" (sectionChange)="onSectionChange($event)">
            @for (tab of tabs; track tab.title; let first = $first) {
                <div class="d-flex align-items-center mb-1">
                    <div class="fs-3 fw-bold flex-grow-1" [ngClass]="{'mt-3': !first}" [attr.id]="encodeString(tab.title)">{{ tab.title }}</div>
                    @if (first && !onCheatSheetPage) {
                        <div>
                            <a target="_blank" class="btn btn-link" [routerLink]="['/', 'help', 'cheat-sheet']">
                                Open in new tab
                                <fa-icon [icon]="newTabIcon" class="fa-icon-right"></fa-icon>
                            </a>
                        </div>
                    }
                </div>
                <div class="text-muted mb-3" [innerHTML]="tab.description"></div>

                @for (group of tab.data; track group.title; let last = $last) {
                    <div ngbAccordion [attr.id]="encodeString(tab.title + '_' + group.title)" [ngClass]="{'mb-3': !last}">
                        <div ngbAccordionItem [collapsed]="false">
                            <h2 ngbAccordionHeader>
                                <div>{{ group.title }}</div>
                            </h2>
                            <div ngbAccordionCollapse></div>
                        </div>
    
                        @for (item of group.data; track item.title) {
                            <div ngbAccordionItem [collapsed]="true">
                                <h2 ngbAccordionHeader>
                                    <fdx-cheat-sheet-panel-header [data]="item" />
                                </h2>
                                <div ngbAccordionCollapse>
                                    <div ngbAccordionBody>
                                        <fdx-cheat-sheet-panel-content [data]="item" />
                                    </div>
                                </div>
                            </div>
                        }
                    </div>
                }
            }
        </div>
    </div>
</div>
