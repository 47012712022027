import { LogAction } from '@app/logs/models/enums/log-action.enum';
import { LogType } from '@app/logs/models/enums/log-type.enum';
import { LogTypeOption } from '@app/logs/models/types/log-type-option.type';
import { LogsAPIRequests } from '@app/logs/models/types/logs.types';

export const CURRENTLY_DOWNLOADING_MSG = 'Tried to run database import, but an import file is currently downloading.';

export const DELETED_IMPORT = 'Deleted import';

export const NO_LOGS_MSG = 'Action succeeded. Log entry exceeds character limit to display.';

export const LOG_TYPE_MAP: Map<LogType, LogTypeOption> = new Map([
    [LogType.ImportExport, {
        value: LogType.ImportExport,
        display: 'Import/export logs',
        route: 'import-export'
    }],
    [LogType.Transformer, {
        value: LogType.Transformer,
        display: 'Transformer logs',
        route: 'transformer'
    }],
    [LogType.FeedAlerts, {
        value: LogType.FeedAlerts,
        display: 'Google Merchant Center logs',
        route: 'feed-alerts'
    }],
    [LogType.Change, {
        value: LogType.Change,
        display: 'Change logs',
        route: 'change'
    }],
    [LogType.ExportEventQueue, {
        value: LogType.ExportEventQueue,
        display: 'Export event queue logs',
        route: 'export-event-queue'
    }],
    [LogType.EDRTS, {
        value: LogType.EDRTS,
        display: 'EDRTS logs',
        route: 'edrts'
    }]
]);

export const FEED_ALERTS_SERVABILITY_MAP: Map<string, number> = new Map([
    ['unaffected', 1],
    ['demoted', 0],
    ['disapproved', -1]
]);

export const FEED_ALERTS_DOWNLOAD_BLACKLIST = new Set([
    '$$hashKey',
    'url',
    'static_api_url',
    'db_id',
    'created_resource_id',
    'api_user_id',
    'request_params',
    'request_body',
    'method',
    'log_method',
    'response_status',
    'response_time',
    'response_body',
    'prior_state'
]);

/**
 * Important: If a new operation is added to this list of enums,
 * Update the OPERATION_NAMES_MAP constant with a human-readable label
 */
export enum OperationEnum {
    AGGREGATE_DUPLICATE_KEY = 'aggregate_duplicate_key',
    AMAZON_API_ERROR = 'amazon_api_error',
    BAD_HTTP_STATUS_CODE = 'bad_http_status_code',
    BAD_INPUT = 'bad_input',
    CREATE_TABLE_ERROR = 'create_table_error',
    CREATE_TEMPORARY_TABLE_FAIL = 'create_temporary_table_fail',
    CURL_ERROR = 'curl_error',
    DATA_GOVERNANCE_ALERT = 'data_governance_alert',
    DATA_GOVERANCE_PREVENT_EXPORT = 'data_governance_prevent_export',
    DATA_OVERRIDE_UNIQUE_VIOLATION = 'data_override_unique_violation',
    DB_IMPORT = 'db_import',
    DEDUPLICATE_FIELD_ERROR = 'deduplicate_field_error',
    DGQ_PARSE_FAIL = 'dgq_parse_fail',
    DGQ_LEX_FAIL = 'dgq_lex_fail',
    DGQ_CREATE_FAIL = 'dgq_create_fail',
    DGQ_CALL_FAIL = 'dgq_call_fail',
    DOMDOCUMENT_MAX_SIZE = 'domdocument_max_size',
    DOWNLOAD = 'download',
    DOWNLOAD_LIMIT = 'download_limit',
    DOWNLOAD_LIMIT_UNCOMPRESSED = 'download_limit_uncompressed',
    EMPTY_FILE = 'empty_file',
    EXPORT = 'export',
    EXPORT_EMPTY_VIOLATION = 'export_empty_violation',
    EXPORT_JSON_FIELD_INVALID = 'export_json_field_invalid',
    EXPORT_LOCAL = 'export_local',
    EXPORT_MISSING_RAD_ASSOCIATION = 'export_missing_rad_association',
    EXPORT_THRESHOLD_VIOLATION = 'export_threshold_violation',
    EXPORT_WEBHOOK_ERROR = 'export_webhook_error',
    EXPORT_WEBHOOK_LOG = 'export_webhook_log',
    GZIP_FAIL = 'gzip_fail',
    IMPORT_CHUNK_MISMATCH = 'import_chunk_mismatch',
    IMPORT_MISSING_HEADER = 'import_missing_header',
    INVALID_CREDENTIALS = 'invalid_credentials',
    INVALID_URL = 'invalid_url',
    INVALID_XML_PATH = 'invalid_xml_path',
    INVALID_ZIP = 'invalid_zip',
    JSON_TO_CSV = 'json_to_csv',
    LOAD_THRESHOLD_VIOLATION = 'load_threshold_violation',
    LOAD_THRESHOLD_PERCENT_VIOLATION = 'load_threshold_percent_violation',
    MISSING_ITEM_GROUP_ID = 'missing_item_group_id',
    NDJSON_TO_CSV = 'ndjson_to_csv',
    NO_FIELDS_FOR_TEMP_TABLE = 'no_fields_for_tmp_table',
    PREPROCESS_ERROR = 'preprocess_error',
    PUBLISH_AND_CONSUME_RABBITMQ = 'publish_and_consume_rabbitmq',
    SFTP_PERMISSIONS_ERROR = 'sftp_permissions_error',
    SFTP_OPERATIONS_ERROR = 'sftp_operations_error',
    SOURCE_FILE_FIELD_MISSING = 'source_file_field_missing',
    SUMMARY_GOVERNANCE_ALERT = 'summary_governance_alert',
    SUMMARY_GOVERNANCE_CONFIGURATION_ERROR = 'summary_governance_configuration_error',
    SUMMARY_GOVERNANCE_INTERNAL_GM_ERROR = 'summary_governance_internal_gm_error',
    SUMMARY_GOVERNANCE_NO_PRIMARY_KEY = 'summary_governance_no_primary_key',
    SUMMARY_GOVERNANCE_PREVENT_IMPORT = 'summary_governance_prevent_import',
    SUMMARY_GOVERNANCE_PREVENT_EXPORT = 'summary_governance_prevent_export',
    TRANSFORMER_CREATE = 'transformer_create',
    TRANSFORMER_FAIL = 'transformer_fail',
    TRANSFORMER_LEX = 'transformer_lex',
    TRANSFORMER_PARSE = 'transformer_parse',
    UPDATE_THRESHOLD_VIOLATION = 'update_threshold_violation',
    XML_TO_CSV = 'xml_to_csv',
    XML_TO_CSV_DOWNLOAD = 'xml_to_csv_download'
}

export const OPERATION_NAMES_MAP: Map<string, string> = new Map([
    [OperationEnum.AGGREGATE_DUPLICATE_KEY, 'Aggregate duplicate key'],
    [OperationEnum.AMAZON_API_ERROR, 'Amazon API error'],
    [OperationEnum.BAD_HTTP_STATUS_CODE, 'Bad http status code'],
    [OperationEnum.BAD_INPUT, 'Input file is malformed'],
    [OperationEnum.CREATE_TABLE_ERROR, 'Create table error'],
    [OperationEnum.CREATE_TEMPORARY_TABLE_FAIL, 'Create tmp table fail'],
    [OperationEnum.CURL_ERROR, 'Curl error'],
    [OperationEnum.DATA_GOVERNANCE_ALERT, 'Data governance alert'],
    [OperationEnum.DATA_GOVERANCE_PREVENT_EXPORT, 'Data governance prevent export'],
    [OperationEnum.DATA_OVERRIDE_UNIQUE_VIOLATION, 'Data governance unique violation'],
    [OperationEnum.DB_IMPORT, 'Import'],
    [OperationEnum.DEDUPLICATE_FIELD_ERROR, 'Duplicate field error'],
    [OperationEnum.DGQ_PARSE_FAIL, 'DGQ parse fail'],
    [OperationEnum.DGQ_LEX_FAIL, 'DGQ lex fail'],
    [OperationEnum.DGQ_CREATE_FAIL, 'DGQ create fail'],
    [OperationEnum.DGQ_CALL_FAIL, 'DGQ call fail'],
    [OperationEnum.DOMDOCUMENT_MAX_SIZE, 'Document max size'],
    [OperationEnum.DOWNLOAD, 'Download'],
    [OperationEnum.DOWNLOAD_LIMIT, 'Download limit'],
    [OperationEnum.DOWNLOAD_LIMIT_UNCOMPRESSED, 'Uncompressed download limit'],
    [OperationEnum.EMPTY_FILE, 'Empty file'],
    [OperationEnum.EXPORT, 'Export'],
    [OperationEnum.EXPORT_EMPTY_VIOLATION, 'Summary empty violation'],
    [OperationEnum.EXPORT_JSON_FIELD_INVALID, 'Invalid JSON'],
    [OperationEnum.EXPORT_LOCAL, 'Local export'],
    [OperationEnum.EXPORT_MISSING_RAD_ASSOCIATION, 'RAD export database error'],
    [OperationEnum.EXPORT_THRESHOLD_VIOLATION, 'Export threshold violation'],
    [OperationEnum.EXPORT_WEBHOOK_ERROR, 'Export webhook error'],
    [OperationEnum.EXPORT_WEBHOOK_LOG, 'Export webhook log'],
    [OperationEnum.GZIP_FAIL, 'GZIP fail'],
    [OperationEnum.IMPORT_CHUNK_MISMATCH, 'Import chunk mismatch'],
    [OperationEnum.IMPORT_MISSING_HEADER, 'Import missing header'],
    [OperationEnum.INVALID_CREDENTIALS, 'Invalid credentials'],
    [OperationEnum.INVALID_URL, 'Invalid URL'],
    [OperationEnum.INVALID_XML_PATH, 'Invalid XML path'],
    [OperationEnum.INVALID_ZIP, 'Invalid zip'],
    [OperationEnum.JSON_TO_CSV, 'JSON did not parse'],
    [OperationEnum.LOAD_THRESHOLD_VIOLATION, 'Load threshold violation'],
    [OperationEnum.LOAD_THRESHOLD_PERCENT_VIOLATION, 'Percent last import threshold violation'],
    [OperationEnum.MISSING_ITEM_GROUP_ID, 'Missing item group id'],
    [OperationEnum.NDJSON_TO_CSV, 'NDJSON did not parse'],
    [OperationEnum.NO_FIELDS_FOR_TEMP_TABLE, 'No fields for tmp table'],
    [OperationEnum.PREPROCESS_ERROR, 'Preprocess error'],
    [OperationEnum.PUBLISH_AND_CONSUME_RABBITMQ, 'Publish and consume RabbitMQ'],
    [OperationEnum.SFTP_PERMISSIONS_ERROR, 'SFTP permissions error'],
    [OperationEnum.SFTP_OPERATIONS_ERROR, 'SFTP operations error'],
    [OperationEnum.SOURCE_FILE_FIELD_MISSING, 'Source file field missing'],
    [OperationEnum.SUMMARY_GOVERNANCE_ALERT, 'Summary governance alert'],
    [OperationEnum.SUMMARY_GOVERNANCE_CONFIGURATION_ERROR, 'Summary governance configuration error'],
    [OperationEnum.SUMMARY_GOVERNANCE_PREVENT_EXPORT, 'Summary governance prevent export'],
    [OperationEnum.SUMMARY_GOVERNANCE_PREVENT_IMPORT, 'Summary governance prevent import'],
    [OperationEnum.SUMMARY_GOVERNANCE_INTERNAL_GM_ERROR, 'Summary governance internal error'],
    [OperationEnum.SUMMARY_GOVERNANCE_NO_PRIMARY_KEY, 'Database primary key'],
    [OperationEnum.TRANSFORMER_CREATE, 'Transformer create'],
    [OperationEnum.TRANSFORMER_FAIL, 'Transformer fail'],
    [OperationEnum.TRANSFORMER_LEX, 'Transformer lex'],
    [OperationEnum.TRANSFORMER_PARSE, 'Transformer parse'],
    [OperationEnum.UPDATE_THRESHOLD_VIOLATION, 'Update threshold violation'],
    [OperationEnum.XML_TO_CSV, 'XML to CSV'],
    [OperationEnum.XML_TO_CSV_DOWNLOAD, 'XML to CSV download']
]);

export const LOGS_API_REQUESTS_MAP: LogsAPIRequests = new Map([
    ['GET|/login', {
        category: 'login',
        action: LogAction.Execute,
        display: 'Login'
    }],
    ['GET|/login/:username/:password', { // Legacy
        category: 'login',
        action: LogAction.Execute,
        display: 'Login'
    }],
    ['PUT|/accept_invite', {
        category: 'invitations',
        action: LogAction.Update,
        display: 'Accept Invitation'
    }],
    ['PUT|/forgot_password', {
        category: 'password',
        action: LogAction.Update,
        display: 'Forgot Password Request'
    }],
    ['PUT|/reset_password', {
        category: 'password',
        action: LogAction.Update,
        display: 'Password'
    }],
    ['POST|/accounts/:account_id/user_account_permissions', {
        category: 'permissions',
        action: LogAction.Create,
        display: 'Account Permissions'
    }],
    ['DELETE|/accounts/:account_id/user_account_permissions/:uap_id', {
        category: 'permissions',
        action: LogAction.Delete,
        display: 'Account Permissions'
    }],
    ['POST|/dbs/:db_id/user_db_permissions', {
        category: 'permissions',
        action: LogAction.Create,
        display: 'Database Permissions'
    }],
    ['DELETE|/dbs/:db_id/user_db_permissions/:udp_id', {
        category: 'permissions',
        action: LogAction.Delete,
        display: 'Database Permissions'
    }],
    ['PUT|/dbs/:db_id/user_db_permissions', {
        category: 'permissions',
        action: LogAction.Update,
        display: 'Database Permissions'
    }],
    ['PUT|/user/update_account_permission/:uap_id', {
        category: 'permissions',
        action: LogAction.Update,
        display: 'Account Permissions'
    }],
    ['PUT|/user/update_db_permission/:udp_id', {
        category: 'permissions',
        action: LogAction.Update,
        display: 'Database Permissions'
    }],
    ['POST|/stripe', {
        category: 'stripe',
        action: LogAction.Create,
        display: 'Stripe Authorization'
    }],
    ['POST|/dbs/:db_id/transformed_data_overrides/:export_id', {
        category: 'transformed_data_overrides',
        action: LogAction.CreateUpdate,
        display: 'Transformed Data Overrides'
    }],
    ['POST|/dbs/:db_id/transformed_data_overrides', {
        category: 'transformed_data_overrides',
        action: LogAction.Delete,
        display: 'Transformed Data Overrides'
    }],
    ['POST|/accounts/:account_id/dbs', {
        category: 'dbs',
        action: LogAction.Create,
        display: 'Database'
    }],
    ['POST|/dbs/:db_id/copy_db', {
        category: 'dbs',
        action: LogAction.Copy,
        display: 'Database'
    }],
    ['DELETE|/dbs/:db_id', {
        category: 'dbs',
        action: LogAction.Delete,
        display: 'Database'
    }],
    ['PUT|/dbs/:db_id', {
        category: 'dbs',
        action: LogAction.Update,
        display: 'Database'
    }],
    ['PUT|/dbs/:db_id/pause', {
        category: 'pause_dbs',
        action: LogAction.Update,
        display: 'Database'
    }],
    ['PUT|/dbs/:db_id/unpause', {
        category: 'pause_dbs',
        action: LogAction.Update,
        display: 'Database'
    }],
    ['POST|/dbs/:db_id/primary_key', {
        category: 'dbs',
        action: LogAction.Update,
        display: 'DB Primary Key'
    }],
    ['PUT|/dbs/:db_id/rename', {
        category: 'dbs',
        action: LogAction.Update,
        display: 'Database'
    }],
    ['PUT|/dbs/:db_id/email_address', {
        category: 'dbs',
        action: LogAction.Update,
        display: 'Email Address (Alerts)'
    }],
    ['PUT|/dbs/:db_id/report_account', {
        category: 'third_party_accounts',
        action: LogAction.Update,
        display: 'Google Account'
    }],
    ['PUT|/dbs/:db_id/report_account_google_analytics', {
        category: 'third_party_accounts',
        action: LogAction.Update,
        display: 'Google Anaylitics Account'
    }],
    ['PUT|/dbs/:db_id/report_account_bing', {
        category: 'third_party_accounts',
        action: LogAction.Update,
        display: 'Bing Account/Campaign'
    }],
    ['POST|/dbs/:db_id/imports', {
        category: 'imports',
        action: LogAction.Create,
        display: 'Import'
    }],
    ['POST|/dbs/:db_id/imports/:import_id', {
        category: 'imports',
        action: LogAction.Update,
        display: 'Import'
    }],
    ['PUT|/dbs/:db_id/imports/:import_id/update_import_fields', {
        category: 'imports',
        action: LogAction.Update,
        display: 'Import Field Update'
    }],
    ['PUT|/dbs/:db_id/imports/:import_id/schedule', {
        category: 'imports',
        action: LogAction.Update,
        display: 'Import Schedule'
    }],
    ['DELETE|/dbs/:db_id/imports/:import_id', {
        category: 'imports',
        action: LogAction.Delete,
        display: 'Import'
    }],
    ['PUT|/dbs/:db_id/imports/:import_id/delimiters', {
        category: 'imports',
        action: LogAction.Update,
        display: 'Import Delimiters'
    }],
    ['PUT|/dbs/:db_id/imports/:import_id/xml_force_parse', {
        category: 'imports',
        action: LogAction.Update,
        display: 'Import XML Force Parse'
    }],
    ['PUT|/dbs/:db_id/imports/:import_id/file_map', {
        category: 'imports',
        action: LogAction.Update,
        display: 'Import File Map'
    }],
    ['PUT|/dbs/:db_id/imports/:import_id/thresholds', {
        category: 'imports',
        action: LogAction.Update,
        display: 'Import Thresholds'
    }],
    ['PUT|/dbs/:db_id/imports/:import_id/retries', {
        category: 'imports',
        action: LogAction.Update,
        display: 'Import Retries'
    }],
    ['PUT|/dbs/:db_id/extra_import_fields', {
        category: 'imports',
        action: LogAction.Update,
        display: 'Extra Import Fields'
    }],
    ['POST|/dbs/:db_id/transformers', {
        category: 'transformers',
        action: LogAction.Create,
        display: 'Transformer'
    }],
    ['DELETE|/dbs/:db_id/transformers', {
        category: 'transformers',
        action: LogAction.Delete,
        display: 'Transformer'
    }],
    ['DELETE|/dbs/:db_id/transformers/:transformer_id', {
        category: 'transformers',
        action: LogAction.Delete,
        display: 'Transformer'
    }],
    ['DELETE|/dbs/:db_id/transformers/:field_name', {
        category: 'transformers',
        action: LogAction.Delete,
        display: 'Transformer'
    }],
    ['PUT|/dbs/:db_id/transformers/:transformer_id', {
        category: 'transformers',
        action: LogAction.Update,
        display: 'Transformer'
    }],
    ['PUT|/dbs/:db_id/transformers/:transformer_id/sort_order', {
        category: 'transformers',
        action: LogAction.Update,
        display: 'Transformer Order'
    }],
    ['POST|/dbs/:db_id/transformers/tokenize-old', {
        category: 'transformers',
        action: LogAction.Create,
        display: 'Tokenize Transformers'
    }],
    ['PUT|/dbs/:db_id/db_fields/', {
        category: 'db_fields',
        action: LogAction.Update,
        display: 'DB Fields Update'
    }],
    ['POST|/dbs/:db_id/exports', {
        category: 'exports',
        action: LogAction.Create,
        display: 'Export'
    }],
    ['PUT|/dbs/:db_id/exports/:export_id', {
        category: 'exports',
        action: LogAction.Update,
        display: 'Export'
    }],
    ['PUT|/dbs/:db_id/exports/:export_id/schedule', {
        category: 'exports',
        action: LogAction.Update,
        display: 'Export Schedule'
    }],
    ['DELETE|/dbs/:db_id/exports/:export_id', {
        category: 'exports',
        action: LogAction.Delete,
        display: 'Export'
    }],
    ['POST|/dbs/:db_id/exports/:export_id/run_parallel_export', {
        category: 'exports',
        action: LogAction.Execute,
        display: 'Push Export'
    }],
    ['POST|/dbs/:db_id/exports/:export_id/run_export', {
        category: 'exports',
        action: LogAction.Execute,
        display: 'Push Export'
    }],
    ['POST|/dbs/:db_id/run_scheduled_exports', {
        category: 'exports',
        action: LogAction.Execute,
        display: 'Push Exports'
    }],
    ['POST|/dbs/:db_id/run_scheduled_imports', {
        category: 'imports',
        action: LogAction.Execute,
        display: 'Run Imports'
    }],
    ['POST|/dbs/:db_id/exports/:export_id/data_governance_queries', {
        category: 'exports',
        action: LogAction.Create,
        display: 'Data Governance'
    }],
    ['POST|/dbs/:db_id/exports/:export_id/download_sample', {
        category: 'exports',
        action: LogAction.Execute,
        display: 'Feed AI Download Sample'
    }],
    ['PUT|/dbs/:db_id/exports/:export_id/data_governance_queries/:data_governance_query_id', {
        category: 'exports',
        action: LogAction.Update,
        display: 'Data Governance'
    }],
    ['DELETE|/dbs/:db_id/exports/:export_id/data_governance_queries/:data_governance_query_id', {
        category: 'exports',
        action: LogAction.Delete,
        display: 'Data Governance'
    }],
    ['POST|/dbs/:db_id/unique_values', {
        category: 'unique_values',
        action: LogAction.Create,
        display: 'Set Map for a Field'
    }],
    ['POST|/dbs/:db_id/join_performance_report', {
        category: 'join_performance_report',
        action: LogAction.Execute,
        display: 'Join Performance Report'
    }],
    ['POST|/dbs/:db_id/ftp_triggers', {
        category: 'ftp',
        action: LogAction.Create,
        display: 'FTP Triggers'
    }],
    ['DELETE|/dbs/:db_id/ftp_triggers/:trigger_id', {
        category: 'ftp',
        action: LogAction.Delete,
        display: 'FTP Triggers'
    }],
    ['POST|/dbs/:db_id/amazon_authorization', {
        category: 'authorization',
        action: LogAction.Create,
        display: 'Amazon Authorization'
    }],
    ['POST|/dbs/:db_id/sears_authorization', {
        category: 'authorization',
        action: LogAction.Create,
        display: 'Sears Authorization'
    }],
    ['POST|/dbs/:db_id/walmart_authorization', {
        category: 'authorization',
        action: LogAction.Create,
        display: 'Walmart Authorization'
    }],
    ['POST|/dbs/:db_id/mercado_libre_authorization', {
        category: 'authorization',
        action: LogAction.Create,
        display: 'Mercado Libre Authorization'
    }],
    ['POST|/dbs/:db_id/ftp_authorization', {
        category: 'authorization',
        action: LogAction.Create,
        display: 'FTP Authorization'
    }],
    ['POST|/dbs/:db_id/polyvore_authorization', {
        category: 'authorization',
        action: LogAction.Create,
        display: 'Polyvore Authorization'
    }],
    ['POST|/dbs/:db_id/google_merchant_center_authorization', {
        category: 'authorization',
        action: LogAction.Create,
        display: 'Google Merchant Center Authorization'
    }],
    ['POST|/dbs/:db_id/gmc_threshold', {
        category: 'feed_alerts',
        action: LogAction.Create,
        display: 'Google Merchant Center Alert Creation'
    }],
    ['POST|/dbs/:db_id/gmc_shopping_actions/test_order', {
        category: 'google_merchant_center',
        action: LogAction.Execute,
        display: 'Google Merchant Center Test Order'
    }],
    ['POST|/dbs/:db_id/feed_config', {
        category: 'feed_config',
        action: LogAction.Update,
        display: 'Feed Pull Configuration'
    }],
    ['POST|/dbs/:db_id/ftp_accounts', {
        category: 'ftp',
        action: LogAction.Create,
        display: 'FTP Account'
    }],
    ['PUT|/dbs/:db_id/ftp_accounts', {
        category: 'ftp',
        action: LogAction.Update,
        display: 'FTP Account'
    }],
    ['PUT|/dbs/:db_id/external_id', {
        category: 'dbs',
        action: LogAction.Update,
        display: 'DB External ID'
    }],
    ['PUT|/dbs/:db_id/transformer_notes', {
        category: 'transformers',
        action: LogAction.Update,
        display: 'Transformer Notes'
    }],
    ['POST|/dbs/:db_id/bulk_transformers', {
        category: 'transformers',
        action: LogAction.Create,
        display: 'Bulk Transformers'
    }],
    ['POST|/emails/send_auth_email', {
        category: 'emails',
        action: LogAction.Create,
        display: 'Send Email'
    }],
    ['POST|/dbs/:db_id/run_imports', {
        category: 'imports',
        action: LogAction.Execute,
        display: 'Run Imports'
    }],
    ['POST|/dbs/:db_id/google_service_account', {
        category: 'authorization',
        action: LogAction.Create,
        display: 'Google Service Account'
    }],
    ['PUT|/dbs/:db_id/google_service_account', {
        category: 'authorization',
        action: LogAction.Copy,
        display: 'Google Service Account'
    }],
    ['POST|/dbs/:db_id/classifier_info', {
        category: 'exports',
        action: LogAction.Update,
        display: 'Feed AI Classifier'
    }],
    ['POST|/dbs/:db_id/clear_cache', {
        category: 'exports',
        action: LogAction.Delete,
        display: 'Feed AI Clear Cache'
    }],
    ['POST|/dbs/:db_id/feed_summary_governance', {
        category: 'summary_governance',
        action: LogAction.Create,
        display: 'Summary Governance'
    }],
    ['PUT|/dbs/:db_id/feed_summary_governance/:summary_id', {
        category: 'summary_governance',
        action: LogAction.Update,
        display: 'Summary Governance'
    }],
    ['POST|/dbs/:db_id/feed_summary_governance/:summary_id/threshold', {
        category: 'summary_governance',
        action: LogAction.Create,
        display: 'Summary Governance Threshold'
    }],
    ['PUT|/dbs/:db_id/feed_summary_governance/:summary_id/threshold/:threshold_id', {
        category: 'summary_governance',
        action: LogAction.Update,
        display: 'Summary Governance Threshold'
    }],
    ['DELETE|/dbs/:db_id/feed_summary_governance/:summary_id/threshold/:threshold_id', {
        category: 'summary_governance',
        action: LogAction.Delete,
        display: 'Summary Governance Threshold'
    }]
]);

/**
 * This is only used for old log entries
 */
export const HUMAN_FRIENDLY_ERRORS_MAP: Map<string, string> = new Map([
    ['general_spam_policy', 'Automatic item disapproval due to policy violation'],
    ['generic_landing_page_redirect', 'Redirect to generic landing page'],
    ['image_link_broken', 'Invalid images'],
    ['image_link_generic', 'Generic images'],
    ['image_link_missing', 'Missing images'],
    ['image_link_roboted', 'Images cannot be crawled because of robots.txt restriction'],
    ['image_link_slow_crawl', 'Ensure that Google can access and crawl your images'],
    ['image_link_too_small', 'Images too small'],
    ['landing_page_error', 'Product pages cannot be accessed'],
    ['landing_page_roboted', 'Product pages cannot be crawled because of robots.txt restriction'],
    ['missing_required_gtin_error', 'Item requires a GTIN'],
    ['mobile_landing_page_error', 'Product pages cannot be accessed from a mobile device'],
    ['mobile_landing_page_roboted', 'Product pages cannot be crawled with a mobile user-agent because of robots.txt restriction'],
    ['forbidden_bulk_gtin_error', 'Item uses a GTIN reserved for bulk products when a valid GTIN is required'],
    ['image_link_small_warning', 'Image too small'],
    ['missing_required_gtin_warning', 'Item requires a GTIN'],
    ['voila_hammer_dangerous_knives', 'Items preemptively disapproved for a policy violation (Dangerous products: dangerous knives)'],
    ['voila_hammer_other_weapons', 'Items preemptively disapproved for a policy violation (Dangerous products: other weapons)'],
    ['wrong_tax', 'Incorrect tax information'],
    ['forbidden_coupon_gtin_error', 'Item uses a coupon GTIN when a valid GTIN is required'],
    ['forbidden_coupon_gtin_warning', 'Coupon code GTIN warning'],
    ['invalid_mime_type', 'Item landing page does not render properly'],
    ['missing_required_gtin_future_', 'Missing Required GTIN'],
    ['mismatching_brand_gtin_prefix_', 'Mismatching brand, gtin_'],
    ['price_mismatch', 'Temporary item disapprovals due to incorrect prices'],
    ['strong_id_inaccurate', 'Incorrect product identifiers'],
    ['title_all_caps', 'Capitalized titles'],
    ['image_link_missing_local_offer', 'Local items - missing image'],
    ['local_missing_description', 'Missing description'],
    ['local_offer_unmatchable', 'Local offer not matching any catalog or online offer'],
    ['availability_mismatch', 'Temporary item disapprovals due to incorrect availability'],
    ['missing_required_item_color', 'Missing Color'],
    ['missing_required_item_size', 'Missing Size'],
    ['multiple_sizes', 'Multiple sizes for one item'],
    ['wrong_shipping', 'Incorrect shipping costs'],
    ['availability_updated', 'Incorrect microdata availability information'],
    ['dq_pid_no_fresh_crawl', 'Preemptive disapproval for price'],
    ['dq_pid_price_no_extraction', 'Preemptive disapproval for price'],
    ['non_normalizable_sizes', 'Size not recognised'],
    ['title_long', 'Long titles']
]);
