<modal-hoc
    [cancelText]="cancelText"
    [modalTheme]="modalTheme"
    [modalTitle]="title"
    [submitText]="confirmText"
    (modalCancelByX)="onModalCancel($event)"
    (modalCancelByButton)="onModalCancel($event)"
    (modalSubmit)="onModalSubmit($event)">
    You have selected FTP, which is an insecure protocol that does not encrypt data during transfer. We strongly recommend using SFTP for encrypted and safer file transfers. Proceeding with FTP may expose your data to security risks, including unauthorized access, interception, and modification.<br/><br/>                    
    By choosing to proceed with FTP, you acknowledge and accept these risks and agree that Feedonomics will not be liable for any data breaches, losses, or damages resulting from the use of FTP. While our team members may assist in configuring FTP at your direction, the ultimate responsibility for selecting and using this protocol rests solely with you.
</modal-hoc>
