import { AccountFeatureFlag } from '@app/core/models/enums/account-feature-flag.enum';
import { AccountDataModel, AccountPermissions, InvitedPermission } from './AccountDataModel';

export class AccountModel implements AccountDataModel {
    account_name: string;
    default_technical_support_email: string;
    features: Record<string, string>;
    id: number;
    logo: string;
    permissions: AccountPermissions;
    invited_permissions: InvitedPermission;

    constructor(data: AccountDataModel) {
        for (const [key, val] of Object.entries(data)) {
            this[key] = val;
        }
    }

    /**
     * Checks the features array for a particular feature value
     * @param feature the name of the feature we're looking for
     * @param value The value to check against for the feature; usually "enabled", but could be something else.
     * Call the function without this value to check if the feature value is set at all.
     * @returns whether the feature exists, and if the "value" variable is set, checks if the value matches the one set for the feature
     */
    hasFeature(feature: string, value: string = undefined): boolean {
        if (!this.features) {
            return false;
        }

        if (value === undefined) {
            return Boolean(this.features[feature]);
        }

        return this.features[feature] === value;
    }

    hasFeatureEnabled(feature: AccountFeatureFlag): boolean {
        return this.hasFeature(feature, 'enabled');
    }

    hasAccountLevelAccess() {
        return !(typeof this.permissions === 'undefined' || this.permissions === null);
    }

}
