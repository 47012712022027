import { Component, Input } from '@angular/core';

@Component({
    selector: 'fdx-form-section',
    styleUrls: ['./form-section.component.scss'],
    templateUrl: 'form-section.component.html'
})
export class FormSectionComponent {
    @Input() header: string;
}
