<div class="position-relative">
    @if (!invalid) {
        <fa-icon
            [icon]="circleIcon"
            size="2xl"
            [ngClass]="initialsClass"/>
    }
    
    <small *ngIf="isSystemUser"
        class="position-absolute top-50 start-50 translate-middle text-white">
        {{ initials }}
    </small>
    <fa-icon *ngIf="isNonSystemUser"
             [icon]="userIcon"
             class="position-absolute top-50 start-50 translate-middle text-white"/>
    @if (invalid) {
        <fa-icon
            [icon]="danger"
            size="2xl"
            class="text-danger"/>
    }
    <span
        *ngIf="hasNotifications"
        class="position-absolute top-0 end-0 p-1 rounded-circle notification-badge">
        <span class="visually-hidden">New release notes</span>
    </span>
</div>
