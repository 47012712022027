import {Component, Input, ViewChild} from '@angular/core';
import {NavGroup} from '@app/modules/nav/components/app-vertical-menu/app-vertical-menu.component';
import {NavStateService} from '@app/modules/nav/services/nav-state.service';
import {IconDefinition} from '@fortawesome/fontawesome-svg-core';
import {faArrowUpRightFromSquare, faChevronRight} from '@fortawesome/pro-solid-svg-icons';
import {NgbAccordionDirective} from '@ng-bootstrap/ng-bootstrap/accordion/accordion.directive';

@Component({
    selector: 'fdx-app-vertical-menu-expanded',
    templateUrl: './app-vertical-menu-expanded.component.html',
    styleUrls: ['./app-vertical-menu-expanded.component.scss', '../app-vertical-menu/app-vertical-menu-buttons.scss']
})
export class AppVerticalMenuExpandedComponent {
    @Input() navGroups: NavGroup[] = [];

    @ViewChild('accordion') accordion: NgbAccordionDirective;

    protected readonly faChevronRight: IconDefinition = faChevronRight;
    protected readonly faArrowUpRightFromSquare: IconDefinition = faArrowUpRightFromSquare;

    constructor(
        protected readonly navStateService: NavStateService,
    ) { }

    getNavGroupClasses(id: string): string {
        if (!this.navStateService.isNavGroupSelected(id)) {
            return 'unselected-item';
        }


        return this.navStateService.isSubNavUnset()
        ? 'selected-item active-selected-item'
        : 'partially-selected-item';
    }

    getSubNavClasses(id: string): string {
        if (!this.navStateService.isSubNavSelected(id)) {
            return 'unselected-sub-nav';
        }

        return 'selected-sub-nav';
    }

    /**
     * Nav Group is only considered fully selected if no sub nav items are selected
     * @param id
     */
    isNavGroupSelected(id: string): boolean {
        return this.navStateService.isNavGroupSelected(id) && this.navStateService.isSubNavUnset();
    }

    isAccordionItemOpen(id: string): boolean {
        return this.accordion?.isExpanded(id);
    }

    onToggleNavGroup(event: MouseEvent): void {
        event.preventDefault();
        event.stopPropagation();
    }

    onNavGroupClick(e: MouseEvent, id: string): void {
        if (e.target instanceof HTMLButtonElement) {
            e.preventDefault();
            return;
        }

        this.accordion.expand(id);
        this.navStateService.onNavGroupClick(id);
    }
}
