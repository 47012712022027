import { Component } from '@angular/core';
import { UntypedFormBuilder } from '@angular/forms';
import { BaseExportFormComponent } from '@app/exports/components/base-export-form/base-export-form.component';
import { ExistingExport } from '@app/exports/models/interfaces/existing-export.interface';
import { faQuestionCircle } from '@fortawesome/pro-solid-svg-icons';

@Component({
    selector: 'fdx-export-retries-form',
    styleUrls: ['export-retries-form.component.scss'],
    templateUrl: 'export-retries-form.component.html'
})
export class ExportRetriesFormComponent extends BaseExportFormComponent {
    iconHelp = faQuestionCircle;

    constructor(
        private fb: UntypedFormBuilder
    ) {
        super();
    }

    protected initForm(): void {
        this.form = this.fb.group({
            maxAttempts: [this.exportItem?.max_attempts ?? 3],
            timeBetweenAttempts: [this.exportItem?.time_between_attempts ?? 30],
        });
    }

    protected patchForm(exportItem: ExistingExport): void {
        this.patchValue({
            maxAttempts: exportItem.max_attempts,
            timeBetweenAttempts: exportItem.time_between_attempts
        });
    }
}
