import { Injectable } from '@angular/core';
import { CategoryPathCellType } from '@app/analyze-data/components/category-paths-cell-renderer/category-paths-cell-renderer.component';
import { CategoryKeyValue } from '@app/analyze-data/components/category-transformer/category-transformer.component';
import { CountryDropdownOption } from '@app/analyze-data/components/column-stats/column-stats.component';
import { COLUMN_STATS_EMPTY } from '@app/analyze-data/constants/analyze-data.constants';
import { CategoryPath } from '@app/category-taxonomy/services/responses/category-paths.response';
import { isEmpty } from '@app/core/functions/isEmpty';
import { ChannelClient } from '@app/core/models/enums/channel-client.enum';
import { IconDefinition, faCircleInfo, faExclamationCircle } from '@fortawesome/pro-solid-svg-icons';

export const ITEM_TYPE_DISPLAY_LIMIT: number = 5;

@Injectable({
    providedIn: 'root'
})
export class CategoryPathsUtilitiesService {

    pathIcon: IconDefinition = faCircleInfo;
    errorIcon: IconDefinition = faExclamationCircle;

    clientIsAmazon(client: string): boolean {
        return client?.toLowerCase().startsWith(ChannelClient.Amazon.toLowerCase());
    }

    clientIsGoogle(client: string): boolean {
        return client === ChannelClient.Google;
    }

    clientIsTarget(client: string): boolean {
        return client === ChannelClient.Target;
    }

    clientIsTikTok(client: string): boolean {
        return client === ChannelClient.TikTok;
    }

    clientIsTikTokExpansion(client: string): boolean {
        return client?.startsWith(ChannelClient.TikTokExpansion);
    }

    getDisplayedValue(config: CategoryPathCellType): string {
        return config.value === '' ? COLUMN_STATS_EMPTY : config.value;
    }

    getDisplayedPath(categoryPath: CategoryPath): string {
        return `${categoryPath.path}${(categoryPath.query ? ` | ${categoryPath.query}` : '')}`;
    }

    /** Should we display paths at all? In the case of Google, the paths are the same as the values.
     */
    shouldDisplayPath(country: CountryDropdownOption): boolean {
        return country ? !this.clientIsGoogle(country.client) : false;
    }

    /** Should we display the path along with the value? This is good for cases when there's only one path,
     * unlike with Amazon.
     */
    shouldDisplayPathInline(country: CountryDropdownOption, category: CategoryKeyValue): boolean {
        return this.shouldDisplayPath(country)
            && !isEmpty(category.value)
            && !this.clientIsAmazon(country.client)
            && category.value.length === 1;
    }

    /** Should we just display the path in a modal format? This is good for cases when there are multiple
     * paths, like with Amazon.
     */
    shouldDisplayPathModal(country: CountryDropdownOption, category: CategoryKeyValue): boolean {
        return this.shouldDisplayPath(country)
            && !isEmpty(category.value)
            && (this.clientIsAmazon(country.client) || category.value.length > 1);
    }

    getComparatorFn(): (valueA: CategoryPathCellType, valueB: CategoryPathCellType) => number {
        return (valueA: CategoryPathCellType, valueB: CategoryPathCellType): number => {
            const a: string = valueA.value.toLowerCase();
            const b: string = valueB.value.toLowerCase();
            if (a === b) {
                return 0;
            }
            return (a > b) ? 1 : -1;
        }
    }

    /**
     * For use when filtering by Amazon product_type, check if the length of item types for a particular
     * group of product types exceeds the ITEM_TYPE_DISPLAY_LIMIT
     */
    hasMoreItemTypesThanLimit(groupedPaths: Record<string, Record<string, CategoryPath[]>>): boolean {
        return Object.values(groupedPaths).some((paths) => Object.values(paths).length > ITEM_TYPE_DISPLAY_LIMIT);
    }

    /**
     * Checks the length of the category paths array within each record of category paths, and if there is more
     * than one path, return true
     */
    hasMultiplePathsPerCategory(groupedPaths: Record<string, Record<string, CategoryPath[]>>): boolean {
        return Object.values(groupedPaths).some((paths) => Object.values(paths).some((pathArray) => pathArray.length > 1));
    }
}
