import { Component, EventEmitter, Input, OnChanges, Output, SimpleChanges } from '@angular/core';
import { AnalystUser } from '@app/feed-support/services/responses/analyst-users.response';

@Component({
    selector: 'fdx-effective-user-select',
    templateUrl: './effective-user-select.component.html',
    styleUrl: './effective-user-select.component.scss'
})
export class EffectiveUserSelectComponent implements OnChanges {

    loadingUsers: boolean = true;

    @Input() displayPlaceholder: boolean = true;
    @Input() analystUsers: AnalystUser[] = [];
    @Input() selectedUser: Partial<AnalystUser>;

    @Output() readonly selectedUserChange: EventEmitter<Partial<AnalystUser>> = new EventEmitter<Partial<AnalystUser>>();

    ngOnChanges(changes: SimpleChanges): void {
        if ('analystUsers' in changes && Array.isArray(changes.analystUsers.currentValue) && changes.analystUsers.currentValue.length > 0) {
            this.loadingUsers = false;
        }
    }

    onUserChange(): void {
        this.selectedUserChange.emit(this.selectedUser);
    }

}
