export enum AccountFeatureFlag {
    BIGCOMMERCE_SELFSERVICE_UX = 'bigcommerce-selfservice-ux',
    CLASSIFIER_INFO = 'classifier_info',
    DATABASE_ALERTS = 'database_alerts',
    EDRTS_REAL_TIME_EXPORT_TRIGGERS = 'edrts_real_time_export_triggers',
    INSECURE_FTP_SUPPORT = 'insecure_ftp_support',
    REMOTE_FILE_UPDATE_TRIGGER = 'remote_file_update_trigger',
    RUN_ATTRIBUTION = 'run_attribution',
    RUN_CLASSIFY = 'run_classify',
    TRANSFORMED_DATA_OVERRIDES = 'transformed_data_overrides'
}

export enum BigCommerceSelfServiceMode {
    FREE = 'free',
    PAID = 'paid',
    NONE = 'none'
}
