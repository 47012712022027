/* eslint-disable @typescript-eslint/no-unsafe-argument */
/* eslint-disable @typescript-eslint/no-unsafe-call */
/* eslint-disable @typescript-eslint/no-unsafe-member-access */
/* eslint-disable @typescript-eslint/no-unsafe-assignment */
import { AccountDataModel, AccountModel } from '@ajs/models/account';
import { UserModel } from '@ajs/models/user';
import AccountsDataService from '@ajs/services/AccountsDataService';
import AppStateService from '@ajs/services/AppStateService';
import FeedSupportStateService from '@ajs/services/FeedSupportStateService';
import ImpersonateUserService from '@ajs/services/ImpersonateUserService';
import LogService from '@ajs/services/LogService';
import UsersDataService from '@ajs/services/UsersDataService';
import { RouteParam } from '@app/core/guards/enums/route-param.enum';
import { StateParams } from '@uirouter/angularjs';
import { IDeferred, IPromise } from 'angular';
import FdxError from '../models/FdxError';

export default class ResolversService {
    /**
     * Hold reference to promises in flight to prevent duplicate API calls
     * from AngularJS resolvers and Angular 14 guards.
     */
    private resolveAccountPromise?: IPromise<AccountDataModel>;
    private resolveDatabasePromise?: IPromise<unknown>;
    private resolveUserPromise?: IPromise<unknown>;
    private resolveStoresPromise?: IPromise<unknown>;

    static readonly $inject: string[] = [
        '$q',
        'AccountsDataService',
        'AppStateService',
        'FeedSupportStateService',
        'LogService',
        'UsersDataService',
        'ImpersonateUserService'
    ];

    constructor(
        private readonly $q: ng.IQService,
        private readonly accountsDataService: AccountsDataService,
        private readonly appStateService: AppStateService,
        private readonly feedSupportStateService: FeedSupportStateService,
        private readonly logService: LogService,
        private readonly usersDataService: UsersDataService,
        private readonly impersonateUserService: ImpersonateUserService
    ) { }

    // eslint-disable-next-line complexity
    public async resolve(toResolve: string[], $stateParams: StateParams): Promise<unknown> {
        const def = this.$q.defer();

        let user = null;
        let account = null;
        let database = null;

        let accountError: unknown = null;

        if (toResolve.includes('user')) {
            user = await this.resolveUser();
        }

        if (toResolve.includes('account')) {
            try {
                account = await this.resolveAccount($stateParams[RouteParam.AccountId]);
            } catch (error) {
                accountError = error;
            }
        }

        if (this.appStateService.getImpersonatedUserId()) {
            user = this.resolveImpersonatedUser();
        }

        if (toResolve.includes('database')) {
            try {
                database = await this.resolveDatabase($stateParams.id);
            } catch (error) {
                def.reject(error); // routesErrorHandler will catch this rejection
                return def.promise;
            }
        }

        // When DB id passed by the URL is associated to a different account, resolve that account.
        if (account && database) {
            if (database.account_id !== account.id) {
                this.appStateService.setAccount(null);
                this.appStateService.saveAccountId(database.account_id);
                const newAccount = await this.resolveAccount();
                this.appStateService.setAccount(newAccount);
            }

            def.resolve();

            return def.promise;
        }

        // When DB id passed by the URL is associated to an account, and the user has multiple accounts,
        // but the they haven't selected an account yet, it is not necessary to redirect them to select account,
        // we can just select the account associated with the database
        if (database && accountError && accountError instanceof FdxError && accountError.key === 'USER_HAS_MULTIPLE_ACCOUNTS') {
            this.appStateService.setAccount(null);
            this.appStateService.saveAccountId(database.account_id);
            const newAccount = await this.resolveAccount();
            this.appStateService.setAccount(newAccount);

            if (toResolve.includes('stores')) {
                await this.resolveStores(database.account_id, 0);
            }
            def.resolve();
            return def.promise;
        }

        if (toResolve.includes('account-reset')) {
            this.appStateService.setAccount(null);
            this.appStateService.setDatabase(null);
        }

        if (accountError instanceof FdxError) {
            def.reject(accountError); // routesErrorHandler will catch this rejection
            return def.promise;
        }

        if (account && user && toResolve.includes('stores')) {
            await this.resolveStores(account.id, 0);
        }

        def.resolve();

        return def.promise;
    }

    private resolveAccount(acctId?: string): IPromise<AccountDataModel> {
        if (this.resolveAccountPromise) {
            return this.resolveAccountPromise;
        }

        let accountId: number;

        // If we have an account_id url route param
        if (acctId) {
            const result = parseInt(acctId);
            if (!isNaN(result)) {
                accountId = result;
            }
        }

        const def: IDeferred<AccountDataModel> = this.$q.defer<AccountDataModel>();
        const account: AccountModel | null = this.appStateService.getAccount();

        if (accountId) {
            this.resolveAccountFromId(accountId, account, def);
        } else {
            this.resolveAccountWithoutId(account, def);
        }

        this.resolveAccountPromise = def.promise
            .finally(
                () => {
                    this.resolveAccountPromise = undefined;
                }
            );

        return this.resolveAccountPromise;
    }

    /**
     * This method uses a valid account id passed in from the route param to load the appropriate account
     * If the url id matches what is saved in memory, we load the account from memory.
     * Otherwise, we fetch the account data using the url account id.
     */
    resolveAccountFromId(accountId: number, account: AccountModel | null, def: IDeferred<AccountDataModel>): void {
        if (account && this.normalizedNumberCompare(account?.id, accountId)) { // loaded account matches url account id
            def.resolve(account); // use existing loaded account
            return;
        }

        // Fetch the account data using the account id from URL route param
        this.accountsDataService.getOneById(accountId)
            .then(
                (data) => {
                    this.appStateService.setAccount(data);
                    this.appStateService.saveAccountId(data.id);
                    def.resolve(this.appStateService.getAccount());
                },
                (error) => {
                    this.logService.error('ResolversService', 'resolveAccount', error);
                    def.reject(error);
                }
            );
    }

    /**
     * This method attempts to resolve the appropriate account.
     * First it checks if there is an account loaded in memory. If so, we use that one.
     * Then it checks if there is an account id stored in the browsers cookies. If so, we fetch data for that account id.
     * If there is no account data, we fetch the accounts the user has permissions to.
     *      If the user only has one account, it will be preselected by default
     *      If the user has multiple accounts, it will be redirected to select an account
     */
    resolveAccountWithoutId(account: AccountModel | null, def: IDeferred<AccountDataModel>): void {
        if (account) {  // is account already loaded in memory?
            def.resolve(account);
            return;
        }

        const savedAccountId = this.appStateService.getSavedAccountId();
        if (savedAccountId) {   // Does the browser's cookie have a saved account id?
            this.accountsDataService.getOneById(savedAccountId)
                .then(
                    (data) => {
                        this.appStateService.setAccount(data);
                        def.resolve(this.appStateService.getAccount());
                    },
                    (error) => {
                        this.logService.error('ResolversService', 'resolveAccount', error);
                        def.reject(error);
                    }
                );
            return;
        }

        // No account data saved in memory
        this.usersDataService.getCurrentUserAccounts()  // fetch the accounts the user has access to
            .then(
                (data) => {
                    if (data.length === 1) {
                        this.appStateService.setAccount(data[0]);
                        this.appStateService.saveAccountId(data[0].id);
                        def.resolve(this.appStateService.getAccount());
                    } else if (data.length > 1) {
                        def.reject(new FdxError('USER_HAS_MULTIPLE_ACCOUNTS', 'User has multiple accounts'));
                    } else {
                        def.reject(new FdxError('USER_HAS_NO_ACCOUNT', 'User has no account'));
                    }
                }
            );
    }

    // If the route requires a selected database
    private resolveDatabase(databaseId: string | number): IPromise<unknown> {
        if (this.resolveDatabasePromise) {
            return this.resolveDatabasePromise;
        }

        const def = this.$q.defer();

        const database = this.appStateService.getDatabase();

        if (this.normalizedNumberCompare(database?.id, databaseId)) {
            def.resolve(database); // database is already loaded
        } else {
            this.appStateService.loadDatabase(databaseId).then(
                (db) => def.resolve(db),
                (error) => def.reject(error)
            );
        }

        this.resolveDatabasePromise = def.promise
            .finally(
                () => {
                    this.resolveDatabasePromise = undefined;
                }
            );

        return this.resolveDatabasePromise;
    }

    // If the route requires a valid logged in user
    private resolveUser(): IPromise<unknown> {
        if (this.resolveUserPromise) {
            return this.resolveUserPromise;
        }

        const def = this.$q.defer();

        const user = this.appStateService.getUser();

        if (user) {
            def.resolve(user); // user is already loaded
        } else {
            const promise = this.usersDataService.retrieveCurrentUser();

            promise.then(
                (data) => {
                    this.appStateService.setUser(data);
                    def.resolve(this.appStateService.getUser());
                },
                (error) => {
                    this.logService.error('ResolversService', 'resolveUser', error);
                    def.reject(error);
                }
            );
        }

        this.resolveUserPromise = def.promise
            .finally(
                () => {
                    this.resolveUserPromise = undefined;
                }
            );

        return this.resolveUserPromise;
    }

    // If the route requires a valid logged in user
    private resolveStores(accountId: string | number, userId: string | number): IPromise<unknown> {
        if (this.resolveStoresPromise) {
            return this.resolveStoresPromise;
        }

        const def = this.$q.defer();

        this.feedSupportStateService.getStores(accountId, userId, true)
            .then((stores) => {
                def.resolve(stores);
            })
            .catch((error) => {
                this.logService.error('ResolversService', 'resolveStores', error);
                def.reject(error);
            });

        this.resolveStoresPromise = def.promise
            .finally(
                () => {
                    this.resolveStoresPromise = undefined;
                }
            );

        return this.resolveStoresPromise;
    }

    // If there is an impersonated_user_id stored in the cookie
    private resolveImpersonatedUser(): UserModel {
        const impersonatedUser = this.impersonateUserService.getUser();

        if (impersonatedUser) {
            return impersonatedUser;
        }

        const impersonatedUserId = this.appStateService.getImpersonatedUserId();
        const loggedInUser = this.appStateService.getLoggedInUser();
        const accountId = this.appStateService.getAccountId();

        this.impersonateUserService.setUser(impersonatedUserId, loggedInUser, accountId);

        return this.impersonateUserService.getUser();
    }

    private normalizedNumberCompare(a: string | number, b: string | number): boolean {
        if (typeof (a) === 'string') {
            a = parseInt(a);
        }

        if (typeof (b) === 'string') {
            b = parseInt(b);
        }

        return a === b;
    }
}
