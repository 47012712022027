<!-- eslint-disable @angular-eslint/template/no-call-expression -->
<div [formGroup]="formGroup">
    <ng-select
        [id]="controlName"
        [formControlName]="controlName"
        [placeholder]="exportsDropdownPlaceholder"
        [items]="orderedExports"
        [searchFn]="exportSearch"
        [trackByFn]="trackExport"
        [clearable]="allowBlank"
        [multiple]="multiple"
        [hideSelected]="multiple"
        [tabIndex]="tabIndex">
        <ng-template
            ng-label-tmp
            let-item="item"
            let-clear="clear">
            <fdx-select-multiple-label-template
                *ngIf="multiple else single"
                [item]="item"
                [clear]="clear">
                <ng-container
                    [ngTemplateOutlet]="label"
                    [ngTemplateOutletContext]="{ export: item }">
                </ng-container>
            </fdx-select-multiple-label-template>

            <ng-template #single>
                <ng-container
                    [ngTemplateOutlet]="label"
                    [ngTemplateOutletContext]="{ export: item }">
                </ng-container>
            </ng-template>
        </ng-template>

        <ng-template
            ng-option-tmp
            let-item="item">
            <ng-container
                [ngTemplateOutlet]="label"
                [ngTemplateOutletContext]="{ export: item }">
            </ng-container>
        </ng-template>
    </ng-select>
</div>

<ng-template
    #label
    let-export="export">
    @let hasIcon = edrtsUtilsService.isValidExportForEBS(export);
    <div
        class="d-flex align-items-center select-container"
        [class.no-icon]="!hasIcon">
        @if (isEBSDatabase) {
            <div class="edrts-icon-container d-flex align-items-center">
                @if (hasIcon) {
                    <img class="edrts-icon"
                        [src]="edrtsIcon(export)"
                        alt="EDRTS icon">
                }
            </div>
        }
        <fa-icon
            *ngIf="export.id !== '0'"
            class="me-2"
            [icon]="getExportIcon(export)"
            [ngClass]="getExportIconClass(export)"
            [fixedWidth]="true">
        </fa-icon>
        <span [title]="export.name" class="titled-text">{{ export.name }}</span>
    </div>

</ng-template>
