<div
    class="d-flex flex-column fdx-category-path-wrapper"
    [ngClass]="{ 'gap-2': !showExtraPaths, 'ms-3': amazonCategoryType }">
    <div *ngIf="amazonCategoryType" class="fdx-path-type">
        <span class="fw-bold">{{ showingProductType ? 'Item' : 'Product' }} type:</span> {{ pathTypeText }}
    </div>
    <ng-container *ngFor="let path of categoryPaths; trackBy: trackByIndex; let first = first">
        <ng-container *ngIf="!showExtraPaths || first || expanded">
            <div
                [ngClass]="{'mt-1': showExtraPaths && first, 'ms-3': showExtraPaths || amazonCategoryType}">
                <div class="fdx-category-path">{{path.path}}</div>
                <div *ngIf="path.query" class="border-start mt-1 mb-2 ps-3 fdx-category-query">when {{path.query}}</div>
            </div>
        </ng-container>
    </ng-container>
    <div *ngIf="showExtraPaths && categoryPaths.length > 1" class="mt-1 ms-3">
        <button
            type="button"
            class="fdx-more-category-paths btn btn-link p-0 white-space-pre"
            [ngClass]="{ 'text-secondary': !expandable }"
            [disabled]="!expandable"
            (click)="toggleExpand()">
            <ng-container *ngIf="!expanded; else showLess" [ngPlural]="categoryPaths.length - 1">
                <ng-template ngPluralCase="=1">+1 more category path</ng-template>
                <ng-template ngPluralCase="other">+{{ categoryPaths.length - 1 }} more category paths</ng-template>
            </ng-container>
            <ng-template #showLess>Show less</ng-template>
        </button>
    </div>
</div>
