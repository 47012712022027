import { Pipe, PipeTransform } from '@angular/core';

@Pipe({name: 'fileSize'})
export class FileSizePipe implements PipeTransform {

    transform(bytes: string | number, decimals: number = 2): string {
        if (bytes === '' || bytes === undefined || bytes === null) {
            return bytes as string;
        }

        let bytesNum: number;
        if (typeof bytes === 'string') {
            const parsedNumber = +bytes;
            if (isNaN(parsedNumber)) {
                return `${ bytes }`
            }

            bytesNum = parsedNumber;
        } else {
            bytesNum = bytes;
        }

        if (bytesNum === 0) {
            return '0 Bytes';
        }

        const k = 1024;
        const dm = decimals < 0 ? 0 : decimals;
        const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];

        const i = Math.floor(Math.log(bytesNum) / Math.log(k));

        return parseFloat((bytesNum / Math.pow(k, i)).toFixed(dm)) + ' ' + sizes[i];
    }
}
