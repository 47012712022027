import AppStateService from '@ajs/services/AppStateService';
import ResolversService from '@ajs/services/ResolversService';
import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Router, RouterStateSnapshot, UrlTree } from '@angular/router';
import { RouteParam } from '@app/core/guards/enums/route-param.enum';
import { LogService } from '@app/core/services/log.service';
import { StateParams } from '@uirouter/angularjs';
import { Observable } from 'rxjs';

@Injectable({
    providedIn: 'root'
})
export class DatabaseGuard {
    constructor(
        private readonly appStateService: AppStateService,
        private readonly logService: LogService,
        private readonly resolversService: ResolversService,
        private readonly router: Router
    ) { }

    private get databasesRoute(): UrlTree {
        const acctId = this.appStateService.getAccountId();
        if (acctId) {
            return this.router.parseUrl(`/${acctId}/databases`);
        }
        return this.router.parseUrl('/');
    }

    public canActivate(route: ActivatedRouteSnapshot, _state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
        // get raw database_id
        const dbid: string = route.paramMap.get(RouteParam.DatabaseId);

        // no database_id route param
        // throw error
        if (!dbid) {
            throw new Error('[DatabaseGuard:canActivate] The database_id param is missing in the route path.');
        }

        // parse database id
        const databaseId: number = parseInt(dbid);

        // was not able to parse database id
        // redirect to databases page
        if (isNaN(databaseId)) {
            return this.databasesRoute;
        }

        return this.resolversService
            .resolve(
                ['database'],
                {
                    id: databaseId
                } as unknown as StateParams
            )
            .then(
                () => {
                    return true;
                }
            )
            .catch(
                (e) => {
                    this.logService.error('[DatabaseGuard:canActivate] Failed to resolve database:', e);
                    return this.databasesRoute;
                }
            );
    }
}
