<div
    ngbAccordion
    class="d-flex flex-column gap-1 px-2 py-3 w-100 h-100"
    #accordion="ngbAccordion"
    [closeOthers]="true">

    @for (navGroup of navGroups; track navGroup.id; let isLast = $last) {
        <div
            [ngbAccordionItem]="navGroup.id"
            [disabled]="!navGroup.children?.length"
            [collapsed]="true"
            [ngClass]="{'mt-auto': isLast}">

            @if (navGroup.isExternalLink) {
                <a
                    ngbAccordionHeader
                    [href]="navGroup.link[0]"
                    target= "_blank"
                    rel="noopener"
                    referrerpolicy="no-referrer"
                    [ngClass]="getNavGroupClasses(navGroup.id)"
                    class="accordion-button custom-header justify-content-between">
                    <ng-container [ngTemplateOutlet]="accordionGroup"></ng-container>
                </a>
            } @else {
                <a
                    ngbAccordionHeader
                    [routerLink]="navGroup.link"
                    (click)="onNavGroupClick($event, navGroup.id)"
                    [ngClass]="getNavGroupClasses(navGroup.id)"
                    class="accordion-button custom-header justify-content-between">
                    <ng-container [ngTemplateOutlet]="accordionGroup"></ng-container>
                </a>
            }
            <ng-template #accordionGroup>
                <p class="m-0 px-1">
                    <fa-icon
                        [icon]="navGroup.icon"
                        [ngClass]="{'text-secondary': !isNavGroupSelected(navGroup.id), 'text-primary-500': isNavGroupSelected(navGroup.id) }"
                        class="me-2 category-icon"/>
                    <span>{{navGroup.label}}</span>
                </p>
                @if (navGroup.isExternalLink) {
                    <fa-icon
                        class="btn btn-link text-secondary no-margin"
                        [icon]="faArrowUpRightFromSquare"/>
                }
                @if (navGroup.children?.length) {
                    <button
                        ngbAccordionToggle
                        type="button"
                        class="btn btn-link btn-ghost btn-nav text-secondary"
                        (click)="onToggleNavGroup($event)">
                        <fa-icon
                            class="no-margin"
                            [icon]="faChevronRight"
                            [transform]="!isAccordionItemOpen(navGroup.id) ? 'rotate-0' : 'rotate-90'"/>
                    </button>
                }
            </ng-template>

            <div ngbAccordionCollapse>
                @if (navGroup.children?.length) {
                    <div ngbAccordionBody class="p-0 pt-1">
                        <ng-template>
                            <div ngbNav #nav="ngbNav" class="nav-pills flex-column w-100" orientation="vertical">
                                @for (subNav of navGroup.children; track subNav.id) {
                                    <ng-container [ngbNavItem]="subNav.id">
                                        <div class="nav-pills-container d-flex" [ngClass]="getSubNavClasses(subNav.id)">
                                            @if (subNav.isExternalLink) {
                                                <a
                                                    [href]="subNav.link[0]"
                                                    target="_blank"
                                                    rel="noopener"
                                                    referrerpolicy="no-referrer"
                                                    class="text-start d-flex justify-content-between align-items-center flex-grow-1 p-1">
                                                    <span>{{subNav.label}}</span>
                                                    <fa-icon
                                                        class="btn btn-link text-secondary p-0 no-margin"
                                                        [icon]="faArrowUpRightFromSquare"/>
                                                </a>
                                            } @else {
                                                <a
                                                    [routerLink]="subNav.link"
                                                    class="text-start d-flex justify-content-between align-items-center flex-grow-1 p-1"
                                                    (click)="navStateService.onSubNavClick(subNav.id, navGroup.id)">
                                                    <span>{{subNav.label}}</span>
                                                </a>
                                            }
                                        </div>
                                    </ng-container>
                                }
                            </div>
                        </ng-template>
                    </div>
                }
            </div>
        </div>
    }
</div>
