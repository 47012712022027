<div class="modal-header" [ngClass]="modalHeaderClasses">
    <fa-icon *ngIf="icon ?? themeType.icon" [icon]="icon ?? themeType.icon" class="me-2"></fa-icon>
    <h3 class="modal-title">{{ modalTitle }}</h3>
    <small *ngIf="isBeta" class="mx-2 align-self-start"><span class="badge text-bg-discovery">BETA</span></small>
    <button
        type="button"
        class="btn-close"
        data-bs-dismiss="modal"
        title="Close"
        aria-label="Close"
        (click)="onModalXClick($event)"
        [disabled]="closeDisabled">
    </button>
</div>
<div class="modal-body" tabindex="-1">
    <ng-content></ng-content>
</div>
<div
    *ngIf="hasFooter"
    class="modal-footer">
    <button
        *ngIf="secondaryButton"
        type="button"
        class="btn"
        [ngClass]="secondaryButtonClasses"
        (click)="onModalSecondary($event)"
        [disabled]="secondaryDisabled">
        {{ secondaryText }}
    </button>
    <button
        *ngIf="submitButton"
        type="button"
        class="modal-submit btn"
		[ngClass]="submitButtonClass"
        (click)="onModalSubmit($event)"
        [disabled]="submitDisabled">
        {{ submitText }}
    </button>
    <button *ngIf="cancelButton" type="button" class="modal-cancel btn btn-outline-secondary" data-bs-dismiss="modal"
        (click)="onModalCancel($event)" [disabled]="cancelDisabled">
        {{ cancelText }}
    </button>
</div>
