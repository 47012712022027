import AppStateService from '@ajs/services/AppStateService';
import { Location } from '@angular/common';
import { Inject, Injectable, NgZone } from '@angular/core';
import { Router } from '@angular/router';
import { LinkService } from '@app/core/services/link.service';
import { KeyboardShortcutConfigurationItem } from '@app/keyboard-shortcuts/interfaces/keyboard-shortcut-configuration-item';
import { WINDOW } from '@feedonomics/frontend-components';
import Mousetrap, { ExtendedKeyboardEvent } from 'mousetrap';

// This would be better created as an initializer, but this can't be done
// until we're entirely off of AngularJS.
@Injectable({
    providedIn: 'root'
})
export class KeyboardShortcutService {

    constructor(
        @Inject(WINDOW) private readonly window: Window,
        private readonly appStateService: AppStateService,
        private readonly linkService: LinkService,
        private readonly router: Router,
        private readonly location: Location,
        private readonly ngZone: NgZone
    ) {
    }

    // path is a function to let it return with the updated database/accountId
    // when the shortcut is called, instead of setting it when the service is first
    // made.
    keyboardShortcuts: KeyboardShortcutConfigurationItem[] = [
        {
            'combination': 'alt+i',
            'description': 'Open up the Imports Tab',
            'path': () => this.linkService.importsLink
        },
        {
            'combination': 'alt+m',
            'description': 'Open up the File Maps Tab',
            'path': () => this.linkService.fileMapsLink
        },
        {
            'combination': 'alt+d',
            'description': 'Open up the Raw Data Tab',
            'path': () => this.linkService.rawDataLink
        },
        {
            'combination': 'alt+x',
            'description': 'Open up the Transformed Data Tab',
            'path': () => this.linkService.dataLink
        },
        {
            'combination': 'alt+t',
            'description': 'Open up the Transformers Tab',
            'path': () => this.linkService.transformersLink
        },
        {
            'combination': 'alt+e',
            'description': 'Open up the Exports Tab',
            'path': () => this.linkService.exportsLink
        },
        {
            'combination': 'alt+l',
            'description': 'Open up the Logs Tab',
            'path': () => this.linkService.logsLink
        },
        {
            'combination': 'alt+a',
            'description': 'Open up the Choose Accounts Tab',
            'path': () => this.linkService.accountsLink,
            'routeType': 'root'
        },
        {
            'combination': 'alt+s',
            'description': 'Open up the Keyboard Shortcuts Tab',
            'path': () => this.linkService.keyboardShortcutLink,
            'routeType': 'root'
        },
        {
            'combination': 'alt+g',
            'description': 'Open up the Feed Alerts Settings Tab',
            'path': () => [...this.linkService.reportsAuthorizationLink, 'google-merchant-center']
        },
        {
            'combination': 'alt+r',
            'description': 'Open up the Alerts Tab',
            'path': () => this.linkService.oldAlertsLink
        },
        {
            'combination': 'alt+z',
            'description': 'Open up the Feed Alerts (GMC) Dashboard',
            'path': () => this.linkService.feedAlertsLink,
            'routeType': 'account'
        }
    ];

    init(): void {
        this.setKeyboardShortcuts();
        this.disableBackspaceKey();
    }

    private setKeyboardShortcuts(): void {
        for (const keyboardShortcut of this.keyboardShortcuts) {
            const combinationArr = keyboardShortcut.combination.split('+');
            const combinationWithShift = combinationArr[0] + '+shift+' + combinationArr[1];

            Mousetrap.bind([keyboardShortcut.combination, combinationWithShift], this.makeKeyboardShortcutCallback(keyboardShortcut));
        }
    }

    private makeKeyboardShortcutCallback(keyboardShortcut: KeyboardShortcutConfigurationItem): (e: ExtendedKeyboardEvent, combo: string) => boolean | void { // this function returns a function to be used as callback

        return (_e: ExtendedKeyboardEvent, combo: string): boolean | void => {
            let path = [];

            switch(keyboardShortcut?.routeType) {
                case 'root':
                    // Root paths don't need any checks, move on to change tab.
                    break;
                case 'account': {
                    const accountId = this.appStateService.getAccountId();
                    if (!accountId) {
                        return false;
                    }
                    break;
                }
                default: {
                    // A db_id is required, i.e. cannot change to a tab that requires one from the accounts tab
                    const databaseId = this.appStateService.getDatabaseId();
                    if (!databaseId) {
                        return false;
                    }
                    break;
                }

            }

            path = keyboardShortcut.path();
            this.changeTab(path, combo);

            return false;

        };

    }

    private disableBackspaceKey(): void {

        this.window.addEventListener('keydown', (e: KeyboardEvent) => {
            if (e.key === 'U+0008' || e.key === 'Backspace') {
                if (e.target === document.body) {
                    e.preventDefault();
                }
            }
        }, true);

    }

    private changeTab(path: string[], combo: string): void {
        if (!combo.includes('shift')) {
            this.ngZone.run(() => {
                void this.router.navigate(path);
            });
        } else {
            const url = this.router.serializeUrl(
                this.router.createUrlTree(path)
            );
            this.window.open(this.location.prepareExternalUrl(url), '_blank');
        }
    }
}
